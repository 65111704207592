<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h5 white--text">Editar Índice de Acerto - {{ selectedFieldName || '' }}</span>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="update"> Salvar </v-btn>
      </v-card-title>
      <v-card-text class="mt-12">
        <v-container>
          <v-row>
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold section-title">Índice de Acerto - {{ finalResult || 'N/A' }}</span>
            </v-col>
          </v-row>
        </v-container>
        <v-spacer></v-spacer>

        <v-container>
          <v-row>
            <v-col>Repetição 01</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="3">
              <custom-label label="Número de Plantas / Metro Linear"/>
              <div @click="openDecisionIndex">
                <simple-text-field v-model="decisionIndexData" :disabled="true"/>
              </div>
            </v-col>

            <v-col cols="3">
              <custom-label label="Utilizado"/>
              <simple-text-field type="number" v-model="firstRepetition.used" @input="calcFirstRepetition" />
            </v-col>

            <v-col cols="3">
              <custom-label label="Diferença"/>
              <simple-text-field v-model="firstRepDiff" :disabled="true"/>
            </v-col>

            <v-col cols="3">
              <custom-label label="Resultado"/>
              <simple-text-field v-model="firstRepResult" :disabled="true"/>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col>Repetição 02</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="3">
              <custom-label label="Número de Plantas / Metro Linear"/>
              <div @click="openDecisionIndex">
                <simple-text-field v-model="decisionIndexData" :disabled="true"/>
              </div>
            </v-col>

            <v-col cols="3">
              <custom-label label="Utilizado"/>
              <simple-text-field type="number" v-model="secondRepetition.used" @input="calcSecondRepetition" />
            </v-col>

            <v-col cols="3">
              <custom-label label="Diferença"/>
              <simple-text-field v-model="secondRepDiff" :disabled="true"/>
            </v-col>

            <v-col cols="3">
              <custom-label label="Resultado"/>
              <simple-text-field v-model="secondRepResult" :disabled="true"/>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col>Repetição 03</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="3">
              <custom-label label="Número de Plantas / Metro Linear"/>
              <div @click="openDecisionIndex">
                <simple-text-field v-model="decisionIndexData" :disabled="true"/>
              </div>
            </v-col>

            <v-col cols="3">
              <custom-label label="Utilizado"/>
              <simple-text-field type="number" v-model="thirdRepetition.used" @input="calcThirdRepetition" />
            </v-col>

            <v-col cols="3">
              <custom-label label="Diferença"/>
              <simple-text-field v-model="thirdRepDiff" :disabled="true"/>
            </v-col>

            <v-col cols="3">
              <custom-label label="Resultado"/>
              <simple-text-field v-model="thirdRepResult" :disabled="true"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ManagementRecords from "@/domain/records/management-records/management-records";
import CustomLabel from "@/components/texts/CustomLabel";
import SimpleTextField from "@/components/inputs/SimpleTextField";

// import IndirectIndex from "@/domain/indexes/indirect-indexes/indirect-indexes";

export default {
  name: "SuccessIndexEdit",
  components: {CustomLabel, SimpleTextField},
  props: {
    managementIndexes: {
      type: Object,
      default: () => {}
    },

    dialog: {
      type: Boolean,
      default: false
    },

    successIndexData: {
      type: Array,
      default: () => [],
    },

    fieldSelected: {
      type: Number
    },

    selectedFieldName: {
      type: String,
    },

    indirectIndexPayload: {
      type: Number,
    },

    oldValue: {
      type: String,
    },

    plantaMetro: {
      type: String,
    },
  },

  data() {
    return {
      payload: {},
      firstRepetition: {},
      secondRepetition: {},
      thirdRepetition: {},
      fieldId: '',
      harvest: '',
      managementRecordService: null,
      grades: ['6.0', '6.5', '7.0', '7.5', '8.0', '8.5', '9.0', '9.5', '10.0'],
      selectedIndex: [],
      data: {
        "repetitions": {"0": 1, "1": 2, "2": 3},
        "collection_point_id": 0,
      },
      firstRepResult: "",
      firstRepDiff: "",
      secondRepResult: "",
      secondRepDiff: "",
      thirdRepResult: "",
      thirdRepDiff: "",
      finalResult: "",
      decisionIndexData:'',
    }
  },

  methods: {
    close() {
      this.$emit('closeDialog');
    },

    save() {
      const data1 = {data1: {}};

      for (let i = 0; i < 3; i++) {
        data1.data1[i] = parseFloat(this.decisionIndexData || 0) || 0;
      }

      const data2 = {"data2": {"0": parseFloat(this.firstRepetition.used) || 0, "1": parseFloat(this.secondRepetition.used) || 0, "2": parseFloat(this.thirdRepetition.used) || 0}}

      const data3 = {
        "data3": {
          "0": parseFloat(this.firstRepResult) || 0, 
          "1": parseFloat(this.secondRepResult) || 0, 
          "2": parseFloat(this.thirdRepResult) || 0
          }
      }

      this.$emit('saveDialog',
          [this.indirectIndexPayload,this.finalResult, Object.assign(this.data, data1, data2, data3), "Índice de Acerto"]);
    },

    calcRepetition(repetition){
      let diff = Math.abs((repetition.used - this.decisionIndexData).toFixed(1));
      let result;
      if(repetition.used)
         result = (10-((diff/this.decisionIndexData)*10)).toFixed(1);
      
      result = result == 0 ? "N/A" : result

      return [diff,result]
    },

    calcFirstRepetition() {

      [this.firstRepDiff, this.firstRepResult] = this.calcRepetition(this.firstRepetition)
      this.getFinalResult();
    },

    calcSecondRepetition() {
      [this.secondRepDiff, this.secondRepResult] = this.calcRepetition(this.secondRepetition)
      this.getFinalResult();
    },

    calcThirdRepetition() {
      [this.thirdRepDiff, this.thirdRepResult] = this.calcRepetition(this.thirdRepetition)
      this.getFinalResult();
    },

    getFinalResult() {
      let soma= 0;
      let n=0;

      const firstResult = Number(this.firstRepResult)
      const secondResult = Number(this.secondRepResult)
      const thirdResult = Number(this.thirdRepResult)

      if(firstResult) {soma+=firstResult;n++;}
      if(secondResult) {soma+=secondResult;n++;}
      if(thirdResult) {soma+=thirdResult;n++;}

      this.finalResult = n > 0 ? `${(soma/n).toFixed(1)}` : "N/A"
      this.finalResult = this.finalResult > 6 ? this.finalResult : 6 
    },

    update() {
      this.getFinalResult();
      this.save();
    },

    loadPreviousResults(){
      this.firstRepetition.used=Number(this.successIndexData[0].data2).toFixed(1);
      this.secondRepetition.used=Number(this.successIndexData[1].data2).toFixed(1);
      this.thirdRepetition.used=Number(this.successIndexData[2].data2).toFixed(1);
      this.decisionIndexData=this.plantaMetro;

      this.calcFirstRepetition();
      this.calcSecondRepetition();
      this.calcThirdRepetition();
    },

    openDecisionIndex() {
      this.$emit("openDecisionIndex")
    }
  },

  watch: {
    fieldSelected(newValue) {
      this.fields           = this.managementIndexes.fields_by_farm.data;
      this.payload.field_id = newValue;

      this.managementIndexes.indexes.forEach(item => {
        this.selectedIndex[item.id] = item.grade;
      });
    }
  },

  beforeMount() {
    this.managementRecordService = ManagementRecords;
    this.finalResult = this.oldValue;
    this.loadPreviousResults();
  }
}
</script>

<style scoped>
.custom-container {
  border: 1px solid #e3e3e3;
  border-radius:5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}

.section-title {
  font-size: 22px;
}
</style>
