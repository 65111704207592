import http from "@/services/http.service";

const apiUrl = "/fields";

class FieldsService {
  static listByFarm(farmId) {
    return http.get(`${apiUrl}/farms/${farmId}`);
  }

  static listWithoutFertilityIndexes(farmId, harvest) {
    return http.get(`${apiUrl}/fertility-indexes/farms/${farmId}/harvests/${harvest}`);
  }

  static create(payload) {
    return http.post(`${apiUrl}`, payload);
  }

  static edit(payload) {
    return http.put(`${apiUrl}/${payload.id}`, payload);
  }

  static toggle(id) {
    return http.get(`${apiUrl}/toggle-status/${id}`);
  }

  static delete(payload) {
    return http.delete(`${apiUrl}/${payload.id}`);
  }
}

export default FieldsService;
