import http from "@/services/http.service";

const apiUrl = "/management-records";

class ManagementRecordsService {
  static list(params) {
    return http.get(`${apiUrl}`, {params});
  }

  static create(payload) {
    return http.post('records', payload);
  }

  static edit(payload) {
    return http.put('records-update', payload);
  }

  static get(farmId, harvest) {
    return http.get(`${apiUrl}/farms/${farmId}/harvests/${harvest}`);
  }

  static getIndexesByManagementAndProducer(producerId) {
    return http.get(`management-indexes/producers/${producerId}`);
  }

  static getIqlPhasesManagementCollectionPoint(farmId, harvest) {
    return http.get(`iql-phases-management-collection-point/farms/${farmId}/harvests/${harvest}`);
  }
}

export default ManagementRecordsService;
