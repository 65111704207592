<template>
  <h2
      :style="'font-size: ' + this.fontSize + 'px'"
      class="pa-0 font-weight-bold text-sm-left mb-8"
  >
    {{ label }}
  </h2>
</template>

<script>
export default {
  name: "CustomH2",
  props: {
    fontSize: {
      type: Number,
      required: false,
      default: 30
    },
    label: {
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped>
  h2 {
    color: #212020;
    opacity: 1;
    line-height: 30px;
  }
</style>