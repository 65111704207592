import http from "@/services/http.service";

const apiUrl = "/indirect-indexes";
const repetitionsApiUrl = "/indirect-index-repetitions";

class IndirectIndexesService {
  static get(payload) {
    return http.post(`${apiUrl}-calculation`, payload);
  }
  static saveData(data) {
    return http.post(`${repetitionsApiUrl}`, data);
  }
  static getData(id) {
    return http.get(`${repetitionsApiUrl}/collection-points/` + id);
  }
  static average(payload) {
    return http.post(`${apiUrl}-average`, payload);
  }
}

export default IndirectIndexesService;
