<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h5 white--text">Editar Expectativa de Colheita - {{ selectedFieldName || '' }}</span>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="update"> Salvar </v-btn>
      </v-card-title>
      <v-card-text class="mt-12">
        <v-container>
          <v-row>
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold section-title">Expectativa de Colheita - {{ finalResult !== "" ? finalResult + ' sc/ha' : 'N/A' }}</span>
            </v-col>

            <v-col class="d-flex justify-end">
              <span class="font-weight-bold section-title">Índice de Expectativa de Colheita - {{ finalHarvestExpectationIndex || 'N/A' }}</span>
            </v-col>
          </v-row>
        </v-container>
        <v-spacer></v-spacer>

        <v-container>
          <v-row>
            <v-col>Repetição 01</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="4">
              <custom-label label="Número de Plantas / Metro"/>
              <simple-text-field type="number" v-model="firstRepetition.number_plants" :placeholder="harvestExpectationIndexData[0].data1 > 0 ? harvestExpectationIndexData[0].data1 : ''" @input="calcFirstRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Espaçamento Entre Linhas"/>
              <simple-text-field :disabled="true" type="number" v-model="firstRepetition.line_spacing" suffix="m"/>
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Vagens / Planta"/>
              <simple-text-field type="number" v-model="firstRepetition.number_pods" :placeholder="harvestExpectationIndexData[0].data3 > 0 ? harvestExpectationIndexData[0].data3 : ''" @input="calcFirstRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Grãos / Vagem"/>
              <simple-text-field type="number" v-model="firstRepetition.number_grains" :placeholder="harvestExpectationIndexData[0].data4 > 0 ? harvestExpectationIndexData[0].data4 : ''" @input="calcFirstRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Peso 1000 Grãos"/>
              <simple-text-field type="number" v-model="firstRepetition.weight_1000_grains" :placeholder="harvestExpectationIndexData[0].data5 > 0 ? harvestExpectationIndexData[0].data5 : ''" @input="calcFirstRepetition" />
            </v-col>

            <v-col>
              <custom-label label="Resultado"/>
              <simple-text-field v-model="firstRepetitionResults" :disabled="true"/>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col>Repetição 02</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="4">
              <custom-label label="Número de Plantas / Metro"/>
              <simple-text-field type="number" v-model="secondRepetition.number_plants" :placeholder="harvestExpectationIndexData[1].data1 > 0 ? harvestExpectationIndexData[1].data1 : ''" @input="calcSecondRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Espaçamento Entre Linhas"/>
              <simple-text-field :disabled="true" type="number" v-model="secondRepetition.line_spacing" suffix="m" @input="calcSecondRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Vagens / Planta"/>
              <simple-text-field type="number" v-model="secondRepetition.number_pods" :placeholder="harvestExpectationIndexData[1].data3 > 0 ? harvestExpectationIndexData[1].data3 : ''" @input="calcSecondRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Grãos / Vagem"/>
              <simple-text-field type="number" v-model="secondRepetition.number_grains" :placeholder="harvestExpectationIndexData[1].data4 > 0 ? harvestExpectationIndexData[1].data4 : ''" @input="calcSecondRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Peso 1000 Grãos"/>
              <simple-text-field type="number" disabled v-model="secondRepetition.weight_1000_grains" :placeholder="harvestExpectationIndexData[1].data5 > 0 ? harvestExpectationIndexData[1].data5 : ''" @input="calcSecondRepetition" />
            </v-col>

            <v-col>
              <custom-label label="Resultado"/>
              <simple-text-field v-model="secondRepetitionResults" :disabled="true"/>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col>Repetição 03</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="4">
              <custom-label label="Número de Plantas / Metro"/>
              <simple-text-field type="number" v-model="thirdRepetition.number_plants" :placeholder="harvestExpectationIndexData[2].data1 > 0 ? harvestExpectationIndexData[2].data1 : ''" @input="calcThirdRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Espaçamento Entre Linhas"/>
              <simple-text-field :disabled="true" type="number" v-model="thirdRepetition.line_spacing" suffix="m" @input="calcThirdRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Vagens / Planta"/>
              <simple-text-field type="number" v-model="thirdRepetition.number_pods" :placeholder="harvestExpectationIndexData[2].data3 > 0 ? harvestExpectationIndexData[2].data3 : ''" @input="calcThirdRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Grãos / Vagem"/>
              <simple-text-field type="number" v-model="thirdRepetition.number_grains" :placeholder="harvestExpectationIndexData[2].data4 > 0 ? harvestExpectationIndexData[2].data4 : ''" @input="calcThirdRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Peso 1000 Grãos"/>
              <simple-text-field type="number" disabled v-model="thirdRepetition.weight_1000_grains" :placeholder="harvestExpectationIndexData[2].data5 > 0 ? harvestExpectationIndexData[2].data5 : ''" @input="calcThirdRepetition" />
            </v-col>

            <v-col>
              <custom-label label="Resultado"/>
              <simple-text-field v-model="thirdRepetitionResults" :disabled="true"/>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold section-title">Expectativa de Colheita - {{ finalResult !== "" ? finalResult + ' sc/ha' : 'N/A' }}</span>
            </v-col>

            <v-col class="d-flex justify-end">
              <span class="font-weight-bold section-title">Índice de Expectativa de Colheita - {{ finalHarvestExpectationIndex || 'N/A' }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ManagementRecords from "@/domain/records/management-records/management-records";
import CustomLabel from "@/components/texts/CustomLabel";
import SimpleTextField from "@/components/inputs/SimpleTextField";

// import IndirectIndex from "@/domain/indexes/indirect-indexes/indirect-indexes";

export default {
  name: "HarvestExpectationEdit",
  components: {CustomLabel, SimpleTextField},
  props: {
    managementIndexes: {
      type: Object,
      default: () => {}
    },

    dialog: {
      type: Boolean,
      default: false
    },

    distributionIndexData: {
      type: Array,
      default: () => []
    },

    harvestExpectationIndexData: {
      type: Array,
      default: () => []
    },

    fieldSelected: {
      type: Number
    },

    selectedFieldName: {
      type: String,
    },

    indirectIndexPayload: {
      type: Number,
    },

    oldValue: {
      type: String,
    }
  },

  data() {
    return {
      payload: {},
      firstRepetition: {},
      secondRepetition: {},
      thirdRepetition: {},
      fieldId: '',
      harvest: '',
      managementRecordService: null,
      grades: ['6.0', '6.5', '7.0', '7.5', '8.0', '8.5', '9.0', '9.5', '10.0'],
      selectedIndex: [],
      data: {
        "repetitions": {"0": 1, "1": 2, "2": 3},
        "collection_point_id": 0,
      },
      firstRepetitionResults: "",
      secondRepetitionResults: "",
      thirdRepetitionResults: "",
      finalResult: "",

      finalHarvestExpectationIndex: "",
      finalNumberPlants: [],
      finalLineSpacing: "",
      finalNumberPods: [],
      finalNumberGrains: [],
      finalWeightGrains: ""
    }
  },

  methods: {
    close() {
      this.$emit('closeDialog');
    },

    save() {

      const data1 = {data1: {"0": parseFloat(this.firstRepetition.number_plants) || 0, "1": parseFloat(this.secondRepetition.number_plants) || 0, "2": parseFloat(this.thirdRepetition.number_plants) || 0}};

      const data2 = {data2: {"0": parseFloat(this.firstRepetition.line_spacing) || 0, "1": parseFloat(this.secondRepetition.line_spacing) || 0, "2": parseFloat(this.thirdRepetition.line_spacing) || 0}};

      const data3 = {data3: {"0": parseFloat(this.firstRepetition.number_pods) || 0, "1": parseFloat(this.secondRepetition.number_pods) || 0, "2": parseFloat(this.thirdRepetition.number_pods) || 0}};

      const data4 = {data4: {"0": parseFloat(this.firstRepetition.number_grains) || 0, "1": parseFloat(this.secondRepetition.number_grains) || 0, "2": parseFloat(this.thirdRepetition.number_grains) || 0}};

      const data5 = {data5: {"0": parseFloat(this.firstRepetition.weight_1000_grains) || 0, "1": parseFloat(this.secondRepetition.weight_1000_grains) || 0, "2": parseFloat(this.thirdRepetition.weight_1000_grains) || 0}};


      this.$emit('saveDialog',
        [this.indirectIndexPayload, this.finalHarvestExpectationIndex, Object.assign(this.data, data1, data2, data3, data4, data5), "Expectativa de Colheita"]);
    },

    calcFirstRepetition() {
      this.payload.number_plants      = Number(this.firstRepetition.number_plants);
      this.payload.number_pods        = Number(this.firstRepetition.number_pods);
      this.payload.number_grains      = Number(this.firstRepetition.number_grains);
      this.payload.weight_1000_grains = Number(this.firstRepetition.weight_1000_grains);
      this.payload.line_spacing       = Number(this.firstRepetition.line_spacing) || 1;

      if (this.payload.number_plants && this.payload.number_pods && this.payload.number_grains && this.payload.weight_1000_grains) {

        this.firstRepetitionResults =
          ((((this.payload.number_plants)*(this.payload.number_pods*(this.payload.number_grains)*((this.payload.weight_1000_grains/1000)/1000))*(10000/this.payload.line_spacing))/60));

        this.firstRepetitionResults = parseFloat(this.firstRepetitionResults.toFixed(2));
      } else {
        this.firstRepetitionResults = 0;
      }

      this.secondRepetition.weight_1000_grains = this.firstRepetition.weight_1000_grains;
      this.calcSecondRepetition();
      
      this.thirdRepetition.weight_1000_grains  = this.firstRepetition.weight_1000_grains;
      this.calcThirdRepetition();

      this.getFinalResult();
    },

    calcSecondRepetition() {
      this.payload.number_plants      = Number(this.secondRepetition.number_plants);
      this.payload.number_pods        = Number(this.secondRepetition.number_pods);
      this.payload.number_grains      = Number(this.secondRepetition.number_grains);
      this.payload.weight_1000_grains = Number(this.secondRepetition.weight_1000_grains);
      this.payload.line_spacing       = Number(this.secondRepetition.line_spacing) || 1;

      if (this.payload.number_plants && this.payload.number_pods && this.payload.number_grains && this.payload.weight_1000_grains) {

        this.secondRepetitionResults =
          ((((this.payload.number_plants)*(this.payload.number_pods*(this.payload.number_grains)*((this.payload.weight_1000_grains/1000)/1000))*(10000/this.payload.line_spacing))/60));

        this.secondRepetitionResults = parseFloat(this.secondRepetitionResults.toFixed(2));
      } else {
        this.secondRepetitionResults = 0;
      }

      this.getFinalResult();
    },

    calcThirdRepetition() {
      this.payload.number_plants      = Number(this.thirdRepetition.number_plants);
      this.payload.number_pods        = Number(this.thirdRepetition.number_pods);
      this.payload.number_grains      = Number(this.thirdRepetition.number_grains);
      this.payload.weight_1000_grains = Number(this.thirdRepetition.weight_1000_grains);
      this.payload.line_spacing       = Number(this.thirdRepetition.line_spacing) || 1;

      if (this.payload.number_plants && this.payload.number_pods && this.payload.number_grains && this.payload.weight_1000_grains) {

        this.thirdRepetitionResults =
          ((((this.payload.number_plants)*(this.payload.number_pods*(this.payload.number_grains)*((this.payload.weight_1000_grains/1000)/1000))*(10000/this.payload.line_spacing))/60));

        this.thirdRepetitionResults = parseFloat(this.thirdRepetitionResults.toFixed(2));
      } else {
        this.thirdRepetitionResults = 0;
      }

      this.getFinalResult();

    },

    getFinalResult() {
      this.finalNumberPlants = [];
      this.finalLineSpacing  = "";
      this.finalNumberPods   = [];
      this.finalNumberGrains = [];
      this.finalWeightGrains = "";

      this.finalNumberPlants.push(
        this.firstRepetition.number_plants,
        this.secondRepetition.number_plants,
        this.thirdRepetition.number_plants
      );

      this.finalNumberPods.push(
        this.firstRepetition.number_pods,
        this.secondRepetition.number_pods,
        this.thirdRepetition.number_pods
      );

      this.finalNumberGrains.push(
        this.firstRepetition.number_grains,
        this.secondRepetition.number_grains,
        this.thirdRepetition.number_grains
      );

      this.finalNumberPlants = this.finalNumberPlants.filter(item => item);
      this.finalNumberPods   = this.finalNumberPods.filter(item => item);
      this.finalNumberGrains = this.finalNumberGrains.filter(item => item);

      if (this.finalNumberPlants.length === 0 ||
          this.finalNumberPods.length === 0 ||
          this.finalNumberGrains.length === 0) {

        this.finalResult = "";
        this.finalHarvestExpectationIndex = "";
        return;
      }

      const resultNumberPlants = `${((this.finalNumberPlants.reduce((s,n)=>{
        const soma = Number(s);
        return soma+Number(n);
      }))/this.finalNumberPlants.length).toFixed(2)}`;

      const resultNumberPods = `${((this.finalNumberPods.reduce((s,n)=>{
        const soma = Number(s);
        return soma+Number(n);
      }))/this.finalNumberPods.length).toFixed(2)}`;

      const resultNumberGrains = `${((this.finalNumberGrains.reduce((s,n)=>{
        const soma = Number(s);
        return soma+Number(n);
      }))/this.finalNumberGrains.length).toFixed(2)}`;

      let harvestExpectation =
        ((((resultNumberPlants)*(resultNumberPods*(resultNumberGrains)*((this.payload.weight_1000_grains/1000)/1000))*(10000/this.payload.line_spacing))/60));

      harvestExpectation = parseFloat(harvestExpectation.toFixed(2));

      this.finalResult = harvestExpectation;
      this.finalHarvestExpectationIndex = this.scale(harvestExpectation);

      return this.finalHarvestExpectationIndex;
    },

    scale(harvestExpectation) {
      let value = "0.0";

      switch (true) {
        case harvestExpectation < 45:
          value = "6.0";
          break;

        case harvestExpectation >= 45 && harvestExpectation <= 50:
          value = "6.5";
          break;

        case harvestExpectation >= 50 && harvestExpectation <= 55:
          value = "7.0";
          break;

        case harvestExpectation >= 55 && harvestExpectation <= 60:
          value = "7.5";
          break;

        case harvestExpectation >= 60 && harvestExpectation <= 65:
          value = "8.0";
          break;

        case harvestExpectation >= 65 && harvestExpectation <= 70:
          value = "8.5";
          break;

        case harvestExpectation >= 70 && harvestExpectation <= 75:
          value = "9.0";
          break;

        case harvestExpectation >= 75 && harvestExpectation <= 80:
          value = "9.5";
          break;

        case harvestExpectation > 80:
          value = "10";
          break;

        default:
          break;
      }

      return value;
    },

    update() {
      this.getFinalResult();
      this.save();
    },

    loadPreviousValues(){

      this.firstRepetition.line_spacing = (Number(this.distributionIndexData[0].data1 / 100)).toFixed(2)
      this.firstRepetition.number_plants= Number(this.harvestExpectationIndexData[0].data1) !== 0 ? this.harvestExpectationIndexData[0].data1 : "";
      this.firstRepetition.number_pods= Number(this.harvestExpectationIndexData[0].data3) !== 0 ? this.harvestExpectationIndexData[0].data3 : "";
      this.firstRepetition.number_grains= Number(this.harvestExpectationIndexData[0].data4) !== 0 ? this.harvestExpectationIndexData[0].data4 : "";
      this.firstRepetition.weight_1000_grains= Number(this.harvestExpectationIndexData[0].data5) !== 0 ? this.harvestExpectationIndexData[0].data5 : "";
      this.calcFirstRepetition()


      this.secondRepetition.line_spacing = (Number(this.distributionIndexData[1].data1 / 100)).toFixed(2)
      this.secondRepetition.number_plants=  Number(this.harvestExpectationIndexData[1].data1) !== 0 ? this.harvestExpectationIndexData[1].data1 : "";
      this.secondRepetition.number_pods=  Number(this.harvestExpectationIndexData[1].data3) !== 0 ? this.harvestExpectationIndexData[1].data3 : "";
      this.secondRepetition.number_grains=  Number(this.harvestExpectationIndexData[1].data4) !== 0 ? this.harvestExpectationIndexData[1].data4 : "";
      this.secondRepetition.weight_1000_grains=  Number(this.harvestExpectationIndexData[1].data5) !== 0 ? this.harvestExpectationIndexData[1].data5 : "";
      this.calcSecondRepetition();

      this.thirdRepetition.line_spacing = (Number(this.distributionIndexData[2].data1 / 100)).toFixed(2)
      this.thirdRepetition.number_plants= Number(this.harvestExpectationIndexData[2].data1) !== 0 ? this.harvestExpectationIndexData[2].data1 : "";
      this.thirdRepetition.number_pods= Number(this.harvestExpectationIndexData[2].data3) !== 0 ? this.harvestExpectationIndexData[2].data3 : "";
      this.thirdRepetition.number_grains= Number(this.harvestExpectationIndexData[2].data4) !== 0 ? this.harvestExpectationIndexData[2].data4 : "";
      this.thirdRepetition.weight_1000_grains= Number(this.harvestExpectationIndexData[2].data5) !== 0 ? this.harvestExpectationIndexData[2].data5 : "";
      this.calcThirdRepetition();

    }
  },

  watch: {
    fieldSelected(newValue) {
      this.fields           = this.managementIndexes.fields_by_farm.data;
      this.payload.field_id = newValue;

      this.managementIndexes.indexes.forEach(item => {
        this.selectedIndex[item.id] = item.grade;
      });
    }
  },

  beforeMount() {
    this.managementRecordService = ManagementRecords;
    this.loadPreviousValues();
    // this.finalResult.average = this.oldValue;
  }
}
</script>

<style scoped>
.custom-container {
  border: 1px solid #e3e3e3;
  border-radius:5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}

.section-title {
  font-size: 22px;
}
</style>
