<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h5 white--text">Editar Índice de Distribuição - {{ selectedFieldName || '' }}</span>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="update"> Salvar </v-btn>
      </v-card-title>
      <v-card-text class="mt-12">
        <v-container>
          <v-row>
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold section-title">Índice de Distribuição - {{ finalResult || 'N/A' }}</span>
            </v-col>
          </v-row>
        </v-container>
        <v-spacer></v-spacer>

        <v-container>
          <v-row>
            <v-col>Repetição 01</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="4">
              <custom-label label="Espaçamento Entre Linhas"/>
              <simple-text-field type="number" v-model="firstRepetition.line_spacing" @input="calcFirstRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Sementes / Metros Linear"/>
              <simple-text-field type="number" v-model="firstRepetition.number_seeds_m" :disabled="true" @input="calcFirstRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Plantas Em 5M"/>
              <simple-text-field type="number" v-model="firstRepetition.number_plants_5m" @input="calcFirstRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Espaçamentos Duplos"/>
              <simple-text-field type="number" v-model="firstRepetition.double_space" @input="calcFirstRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Espaçamentos Falhos"/>
              <simple-text-field type="number" v-model="firstRepetition.faulty_spacing" @input="calcFirstRepetition" />
            </v-col>

            <v-col>
              <custom-label label="Resultado"/>
              <simple-text-field v-model="firstRepetitionResults" :disabled="true"/>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col>Repetição 02</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="4">
              <custom-label label="Espaçamento Entre Linhas"/>
              <simple-text-field type="number" :disabled="true" v-model="firstRepetition.line_spacing" @input="calcSecondRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Sementes / Metros Linear"/>
              <simple-text-field type="number" :disabled="true" v-model="secondRepetition.number_seeds_m" @input="calcSecondRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Plantas Em 5M"/>
              <simple-text-field type="number" v-model="secondRepetition.number_plants_5m" @input="calcSecondRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Espaçamentos Duplos"/>
              <simple-text-field type="number" v-model="secondRepetition.double_space" @input="calcSecondRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Espaçamentos Falhos"/>
              <simple-text-field type="number" v-model="secondRepetition.faulty_spacing" @input="calcSecondRepetition" />
            </v-col>

            <v-col>
              <custom-label label="Resultado"/>
              <simple-text-field v-model="secondRepetitionResults" :disabled="true"/>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col>Repetição 03</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="4">
              <custom-label label="Espaçamento Entre Linhas"/>
              <simple-text-field type="number" :disabled="true" v-model="firstRepetition.line_spacing" @input="calcThirdRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Sementes / Metros Linear"/>
              <simple-text-field type="number" v-model="thirdRepetition.number_seeds_m" :disabled="true" @input="calcThirdRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Plantas Em 5M"/>
              <simple-text-field type="number" v-model="thirdRepetition.number_plants_5m" @input="calcThirdRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Espaçamentos Duplos"/>
              <simple-text-field type="number" v-model="thirdRepetition.double_space" @input="calcThirdRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Espaçamentos Falhos"/>
              <simple-text-field type="number" v-model="thirdRepetition.faulty_spacing" @input="calcThirdRepetition" />
            </v-col>

            <v-col>
              <custom-label label="Resultado"/>
              <simple-text-field v-model="thirdRepetitionResults" :disabled="true"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ManagementRecords from "@/domain/records/management-records/management-records";
import CustomLabel from "@/components/texts/CustomLabel";
import SimpleTextField from "@/components/inputs/SimpleTextField";

// import IndirectIndex from "@/domain/indexes/indirect-indexes/indirect-indexes";

export default {
  name: "DistributionIndexEdit",
  components: {CustomLabel, SimpleTextField},
  props: {
    managementIndexes: {
      type: Object,
      default: () => {}
    },

    dialog: {
      type: Boolean,
      default: false
    },

    decisionIndexData: {
      type: Array,
      default: () => []
    },

    distributionIndexData: {
      type: Array,
      default: () => []
    },

    fieldSelected: {
      type: Number
    },

    selectedFieldName: {
      type: String,
    },

    indirectIndexPayload: {
      type: Number,
    },

    oldValue: {
      type: String,
    }
  },

  data() {
    return {
      payload: {},
      firstRepetition: {},
      secondRepetition: {},
      thirdRepetition: {},
      fieldId: '',
      harvest: '',
      managementRecordService: null,
      grades: ['6.0', '6.5', '7.0', '7.5', '8.0', '8.5', '9.0', '9.5', '10.0'],
      selectedIndex: [],
      data: {
        "repetitions": {"0": 1, "1": 2, "2": 3},
        "collection_point_id": 0,
      },
      firstRepetitionResults: "",
      secondRepetitionResults: "",
      thirdRepetitionResults: "",
      finalResult: "",
    }
  },

  methods: {
    close() {
      this.$emit('closeDialog');
    },

    save() {
      const data1 = {data1: {}};

      for (let i = 0; i < 3; i++) {
        data1.data1[i] = parseFloat(this.firstRepetition.line_spacing) || 0;
      }

      const data2 = {data2: {"0": parseFloat(this.firstRepetition.number_seeds_m || 0) || 0, "1": parseFloat(this.secondRepetition.number_seeds_m || 0) || 0, "2": parseFloat(this.thirdRepetition.number_seeds_m || 0) || 0}};
      const data3 = {data3: {"0": parseFloat(this.firstRepetition.number_plants_5m) || 0, "1": parseFloat(this.secondRepetition.number_plants_5m) || 0, "2": parseFloat(this.thirdRepetition.number_plants_5m) || 0}};
      const data4 = {data4: {"0": parseFloat(this.firstRepetition.double_space) || 0, "1": parseFloat(this.secondRepetition.double_space) || 0, "2": parseFloat(this.thirdRepetition.double_space) || 0}};
      const data5 = {data5: {"0": parseFloat(this.firstRepetition.faulty_spacing) || 0, "1": parseFloat(this.secondRepetition.faulty_spacing) || 0, "2": parseFloat(this.thirdRepetition.faulty_spacing) || 0}};

      this.$emit('saveDialog',
          [this.indirectIndexPayload, this.finalResult, Object.assign(this.data, data1, data2, data3, data4, data5), "Índice de Distribuição"]);
    },

    calcFirstRepetition() {
      let dif;
      if(this.firstRepetition.number_plants_5m != 0 && this.firstRepetition.double_space != 0 && this.firstRepetition.faulty_spacing != 0)
        dif = (100-((((this.firstRepetition.number_plants_5m || 0 -1)*this.firstRepetition.double_space || 0)/100)+(((this.firstRepetition.number_plants_5m || 0 -1)*this.firstRepetition.faulty_spacing || 0)/100)))/10;
      else
        dif=''

      if(dif){
        this.firstRepetitionResults = dif.toFixed(1);
        this.getFinalResult();
      }
    },

    calcSecondRepetition() {
      let dif;
      if(this.secondRepetition.number_plants_5m != 0 && this.secondRepetition.double_space != 0 && this.secondRepetition.faulty_spacing != 0)
        dif = (100-((((this.secondRepetition.number_plants_5m || 0 -1)*this.secondRepetition.double_space || 0)/100)+(((this.secondRepetition.number_plants_5m || 0 -1)*this.secondRepetition.faulty_spacing || 0)/100)))/10;
      else
        dif=''
      
      if(dif){
        this.secondRepetitionResults = dif.toFixed(1);
        this.getFinalResult();
      }
    },

    calcThirdRepetition() {
      let dif;
      if(this.thirdRepetition.number_plants_5m != 0 && this.thirdRepetition.double_space != 0 && this.thirdRepetition.faulty_spacing != 0)      
        dif = (100-((((this.thirdRepetition.number_plants_5m || 0 -1)*this.thirdRepetition.double_space || 0)/100)+(((this.thirdRepetition.number_plants_5m || 0 -1)*this.thirdRepetition.faulty_spacing || 0)/100)))/10;
      else
        dif=''

      if(dif){  
        this.thirdRepetitionResults = dif.toFixed(1);
        this.getFinalResult();
      } 
    },

    getFinalResult() {
      let grades=[];
    
      this.payload = {};

      this.payload.repetition1 = this.firstRepetitionResults || 0;
      this.payload.repetition2 = this.secondRepetitionResults || 0;
      this.payload.repetition3 = this.thirdRepetitionResults || 0;

      if(this.payload.repetition1 != 0)
        grades.push(this.payload.repetition1)
      if(this.payload.repetition2 != 0)
        grades.push(this.payload.repetition2)
      if(this.payload.repetition3 != 0)
        grades.push(this.payload.repetition3)

      this.finalResult = `${((grades.reduce((s,n)=>{
        const soma = Number(s);
        return soma+Number(n);
      }))/grades.length).toFixed(1)}`

    },

    update() {
      this.getFinalResult();
      this.save();
    },

    loadPreviousResults(){
      let numberSeeds = this.decisionIndexData.map(data => Number(data.data2)) 
      numberSeeds=numberSeeds.filter(value => value? value: false)
      numberSeeds = (numberSeeds.reduce((s,n)=>{
        const soma = Number(s);
        return soma+Number(n);
      }))/numberSeeds.length      

      this.firstRepetition.line_spacing = this.distributionIndexData[0].data1 || 0;
      this.firstRepetition.number_seeds_m = `${numberSeeds.toFixed(2)}` || 0;
      this.firstRepetition.number_plants_5m = this.distributionIndexData[0].data3 || 0;
      this.firstRepetition.double_space = this.distributionIndexData[0].data4 || 0;
      this.firstRepetition.faulty_spacing = this.distributionIndexData[0].data5 || 0;
      this.calcFirstRepetition();

      this.secondRepetition.line_spacing=this.distributionIndexData[1].data2;
      this.secondRepetition.number_seeds_m = `${numberSeeds.toFixed(2)}` || 0;
      this.secondRepetition.number_plants_5m = this.distributionIndexData[1].data3 || 0;
      this.secondRepetition.double_space = this.distributionIndexData[1].data4 || 0;
      this.secondRepetition.faulty_spacing = this.distributionIndexData[1].data5 || 0;
      this.calcSecondRepetition();

      this.thirdRepetition.line_spacing=this.distributionIndexData[2].data2;
      this.thirdRepetition.number_seeds_m = `${numberSeeds.toFixed(2)}` || 0;
      this.thirdRepetition.number_plants_5m = this.distributionIndexData[2].data3 || 0;
      this.thirdRepetition.double_space = this.distributionIndexData[2].data4 || 0;
      this.thirdRepetition.faulty_spacing = this.distributionIndexData[2].data5 || 0;
      this.calcThirdRepetition();
    }
  },

  watch: {
    fieldSelected(newValue) {
      this.fields           = this.managementIndexes.fields_by_farm.data;
      this.payload.field_id = newValue;

      this.managementIndexes.indexes.forEach(item => {
        this.selectedIndex[item.id] = item.grade;
      });
    }
  },

  beforeMount() {
    this.loadPreviousResults()
    this.managementRecordService = ManagementRecords;
    this.finalResult.average = this.oldValue;
  }
}
</script>

<style scoped>
.custom-container {
  border: 1px solid #e3e3e3;
  border-radius:5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}

.section-title {
  font-size: 22px;
}
</style>
