<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h5 white--text"
          >Editar Ponto - {{ selectedFieldName || "" }}</span
        >
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="update"> Atualizar</v-btn>
      </v-card-title>
      <v-card-text class="mt-12">
        <v-container class="pl-0 mb-8">
          <v-row>
            <v-col cols="12" md="4" sm="6">
              <custom-label label="Talhão" />
              <simple-select
                v-model="fieldId"
                :items="fieldsByFarm"
                itemText="name"
                itemValue="id"
                placeholder="Selecione um talhão"
                :menuProps="{
                  bottom: true,
                  offsetY: true,
                  closeOnClick: true,
                  maxHeight: 200,
                }"
                :disabled="selectedFieldId !== ''"
              >
              </simple-select>
            </v-col>
            <v-col cols="12" md="4" sm="6"></v-col>
            <v-col cols="12" md="4" sm="6">
              <div class="d-flex flex-column">
                <custom-label class="ma-0" label="Localização" noMargin />
                <v-row>
                  <v-col class="d-flex justify-space-between align-center">
                    <p class="ma-0 text-decoration-underline">
                      {{
                        !!newFile.name
                          ? newFile.name
                          : file
                          ? file.original_name
                          : "Sem Arquivo"
                      }}
                    </p>
                    <div class="d-flex justify-space-between">
                      <v-btn
                        v-if="file"
                        @click="downloadFile"
                        title="Baixar Arquivo"
                        class="mr-2"
                        :disabled="file.url === ''"
                      >
                        <v-icon> mdi-download </v-icon>
                      </v-btn>
                      <v-btn
                        @click="() => (showChangeLocationDialog = true)"
                        title="Editar Arquivo"
                      >
                        <v-icon center> mdi-pencil </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row align="center">
            <v-col cols="12" class="d-flex justify-start pl-0">
              <span class="font-weight-bold section-title">Pós - Plantio</span>
            </v-col>
            <v-row class="mt-2">
              <template v-for="managementIndex in managementIndexes">
                <v-col
                  cols="12"
                  md="6"
                  :key="managementIndex.id"
                  v-if="
                    !isSortableIndex(managementIndex.name) &&
                    managementIndex.phase === 'Pós-Plantio'
                  "
                >
                  <p>{{ managementIndex.name }}</p>

                  <div
                    @click="openDecisionIndexEdit(managementIndex.id)"
                    v-if="managementIndex.name === 'Índice de Decisão'"
                  >
                    <v-select
                      v-model="selectedIndex[managementIndex.id]"
                      :items="['']"
                      :label="
                        decisionIndex ? decisionIndex : managementIndex.name
                      "
                      :item-value="managementIndex.id.toString()"
                      class="rounded-xl mt-2 bordered-input font-weight-light"
                      solo
                      append-icon="mdi-pencil"
                    />
                  </div>

                  <div
                    @click="openSuccessIndexEdit(managementIndex.id)"
                    v-else-if="managementIndex.name === 'Índice de Acerto'"
                  >
                    <v-select
                      v-model="selectedIndex[managementIndex.id]"
                      :items="grades"
                      :label="succesIndex ? succesIndex : managementIndex.name"
                      :item-value="managementIndex.id.toString()"
                      class="rounded-xl mt-2 bordered-input font-weight-light"
                      solo
                      append-icon="mdi-pencil"
                    />
                  </div>

                  <div
                    @click="openDistributionIndexEdit(managementIndex.id)"
                    v-else-if="
                      managementIndex.name === 'Índice de Distribuição'
                    "
                  >
                    <v-select
                      v-model="selectedIndex[managementIndex.id]"
                      :items="grades"
                      :label="
                        distributionIndex
                          ? distributionIndex
                          : managementIndex.name
                      "
                      :item-value="managementIndex.id.toString()"
                      class="rounded-xl mt-2 bordered-input font-weight-light"
                      solo
                      append-icon="mdi-pencil"
                    />
                  </div>

                  <v-select
                    v-else
                    v-model="selectedIndex[managementIndex.id]"
                    :items="grades"
                    :label="managementIndex.name"
                    :item-value="managementIndex.id.toString()"
                    class="rounded-xl mt-2 bordered-input font-weight-light"
                    solo
                  />
                </v-col>
              </template>
              <template v-for="managementIndex in managementIndexes">
                <v-col
                  cols="12"
                  md="6"
                  :key="managementIndex.id"
                  v-if="
                    isSortableIndex(managementIndex.name) &&
                    managementIndex.phase === 'Pós-Plantio'
                  "
                >
                  <p>{{ managementIndex.name }}</p>

                  <div
                    @click="openDecisionIndexEdit(managementIndex.id)"
                    v-if="managementIndex.name === 'Índice de Decisão'"
                  >
                    <v-select
                      v-model="selectedIndex[managementIndex.id]"
                      :items="['']"
                      :label="
                        decisionIndex ? decisionIndex : managementIndex.name
                      "
                      :item-value="managementIndex.id.toString()"
                      class="rounded-xl mt-2 bordered-input font-weight-light"
                      solo
                      append-icon="mdi-pencil"
                    />
                  </div>

                  <div
                    @click="openSuccessIndexEdit(managementIndex.id)"
                    v-else-if="managementIndex.name === 'Índice de Acerto'"
                  >
                    <v-select
                      v-model="selectedIndex[managementIndex.id]"
                      :items="grades"
                      :label="succesIndex ? succesIndex : managementIndex.name"
                      :item-value="managementIndex.id.toString()"
                      class="rounded-xl mt-2 bordered-input font-weight-light"
                      solo
                      append-icon="mdi-pencil"
                    />
                  </div>

                  <div
                    @click="openDistributionIndexEdit(managementIndex.id)"
                    v-else-if="
                      managementIndex.name === 'Índice de Distribuição'
                    "
                  >
                    <v-select
                      v-model="selectedIndex[managementIndex.id]"
                      :items="grades"
                      :label="
                        distributionIndex
                          ? distributionIndex
                          : managementIndex.name
                      "
                      :item-value="managementIndex.id.toString()"
                      class="rounded-xl mt-2 bordered-input font-weight-light"
                      solo
                      append-icon="mdi-pencil"
                    />
                  </div>

                  <v-select
                    v-else
                    v-model="selectedIndex[managementIndex.id]"
                    :items="grades"
                    :label="managementIndex.name"
                    :item-value="managementIndex.id.toString()"
                    class="rounded-xl mt-2 bordered-input font-weight-light"
                    solo
                  />
                </v-col>
              </template>
            </v-row>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row align="center">
            <v-col cols="12" class="d-flex justify-start pl-0">
              <span class="font-weight-bold section-title"
                >Pós - Florescimento</span
              >
            </v-col>

            <v-row class="mt-2">
              <template v-for="managementIndex in managementIndexes">
                <v-col
                  cols="12"
                  md="6"
                  :key="managementIndex.id"
                  v-if="
                    !isSortableIndex(managementIndex.name) &&
                    managementIndex.phase === 'Pós-Florescimento'
                  "
                >
                  <p>{{ managementIndex.name }}</p>

                  <div
                    @click="openHarvestExpectation(managementIndex.id)"
                    v-if="managementIndex.name === 'Expectativa de Colheita'"
                  >
                    <v-select
                      v-model="selectedIndex[managementIndex.id]"
                      :items="grades"
                      :label="
                        harvestExpectationIndex
                          ? harvestExpectationIndex
                          : managementIndex.name
                      "
                      :item-value="managementIndex.id.toString()"
                      class="rounded-xl mt-2 bordered-input font-weight-light"
                      solo
                      append-icon="mdi-pencil"
                    />
                  </div>

                  <div
                    @click="openNutritionalBalanceEdit(managementIndex.id)"
                    v-else-if="
                      managementIndex.name === 'Índice de Balanço Nutricional'
                    "
                  >
                    <v-select
                      v-model="selectedIndex[managementIndex.id]"
                      :items="grades"
                      :label="
                        selectedIndex[managementIndex.id]
                          ? selectedIndex[managementIndex.id]
                          : nutricionalBalanceIndexData[0].grade || '0.0'
                      "
                      :item-value="managementIndex.id.toString()"
                      class="rounded-xl mt-2 bordered-input font-weight-light"
                      solo
                      append-icon="mdi-pencil"
                    />
                  </div>

                  <v-select
                    v-else
                    v-model="selectedIndex[managementIndex.id]"
                    :items="grades"
                    :item-value="managementIndex.id.toString()"
                    :label="managementIndex.name"
                    class="rounded-xl mt-2 bordered-input font-weight-light"
                    solo
                  />
                </v-col>
              </template>
              <template v-for="managementIndex in managementIndexes">
                <v-col
                  cols="12"
                  md="6"
                  :key="managementIndex.id"
                  v-if="
                    isSortableIndex(managementIndex.name) &&
                    managementIndex.phase === 'Pós-Florescimento'
                  "
                >
                  <p>{{ managementIndex.name }}</p>

                  <div
                    @click="openHarvestExpectation(managementIndex.id)"
                    v-if="managementIndex.name === 'Expectativa de Colheita'"
                  >
                    <v-select
                      v-model="selectedIndex[managementIndex.id]"
                      :items="grades"
                      :label="
                        harvestExpectationIndex
                          ? harvestExpectationIndex
                          : managementIndex.name
                      "
                      :item-value="managementIndex.id.toString()"
                      class="rounded-xl mt-2 bordered-input font-weight-light"
                      solo
                      append-icon="mdi-pencil"
                    />
                  </div>

                  <div
                    @click="openNutritionalBalanceEdit(managementIndex.id)"
                    v-else-if="
                      managementIndex.name === 'Índice de Balanço Nutricional'
                    "
                  >
                    <v-select
                      v-model="selectedIndex[managementIndex.id]"
                      :items="grades"
                      :label="
                        selectedIndex[managementIndex.id]
                          ? selectedIndex[managementIndex.id]
                          : nutricionalBalanceIndexData[0].grade || '0.0'
                      "
                      :item-value="managementIndex.id.toString()"
                      class="rounded-xl mt-2 bordered-input font-weight-light"
                      solo
                      append-icon="mdi-pencil"
                    />
                  </div>

                  <v-select
                    v-else
                    v-model="selectedIndex[managementIndex.id]"
                    :items="grades"
                    :item-value="managementIndex.id.toString()"
                    :label="managementIndex.name"
                    class="rounded-xl mt-2 bordered-input font-weight-light"
                    solo
                  />
                </v-col>
              </template>
            </v-row>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row align="center">
            <v-col cols="12" class="d-flex justify-start pl-0">
              <span class="font-weight-bold section-title">Colheita</span>
            </v-col>

            <v-row class="mt-2">
              <template v-for="managementIndex in managementIndexes">
                <v-col
                  cols="12"
                  md="6"
                  :key="managementIndex.id"
                  v-if="
                    !isSortableIndex(managementIndex.name) &&
                    managementIndex.phase === 'Colheita'
                  "
                >
                  <p>{{ managementIndex.name }}</p>

                  <v-select
                    v-model="selectedIndex[managementIndex.id]"
                    :items="grades"
                    :item-value="managementIndex.id.toString()"
                    :label="managementIndex.name"
                    class="rounded-xl mt-2 bordered-input font-weight-light"
                    solo
                  />
                </v-col>
              </template>
              <template v-for="managementIndex in managementIndexes">
                <v-col
                  cols="12"
                  md="6"
                  :key="managementIndex.id"
                  v-if="
                    isSortableIndex(managementIndex.name) &&
                    managementIndex.phase === 'Colheita'
                  "
                >
                  <p>{{ managementIndex.name }}</p>

                  <v-select
                    v-model="selectedIndex[managementIndex.id]"
                    :items="grades"
                    :item-value="managementIndex.id.toString()"
                    :label="managementIndex.name"
                    class="rounded-xl mt-2 bordered-input font-weight-light"
                    solo
                  />
                </v-col>
              </template>
            </v-row>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <decision-index-edit
      v-if="showDecisionIndexEdit"
      :selectedFieldName="selectedFieldName"
      :decisionIndexData="decisionIndexData"
      :dialog="showDecisionIndexEdit"
      :indirectIndexPayload="indirectIndexPayload"
      @closeDialog="closeDecisionIndexModal"
      @saveDialog="updateDecisionIndex($event)"
    />

    <success-index-edit
      v-if="showSuccessIndexEdit"
      :selectedFieldName="selectedFieldName"
      :dialog="showSuccessIndexEdit"
      :indirectIndexPayload="indirectIndexPayload"
      :plantaMetro="decisionIndexPlantaMetro"
      :successIndexData="successIndexData"
      @closeDialog="closeModals"
      @saveDialog="updateIndex($event)"
      @openDecisionIndex="openDecisionIndexEdit(62)"
    />

    <distribution-index-edit
      v-if="showDistributionIndexEdit"
      :decisionIndexData="decisionIndexData"
      :distributionIndexData="distributionIndexData"
      :selectedFieldName="selectedFieldName"
      :dialog="showDistributionIndexEdit"
      :indirectIndexPayload="indirectIndexPayload"
      @closeDialog="closeModals"
      @saveDialog="updateIndex($event)"
    />

    <harvest-expectation-edit
      v-if="showHarvestExpectationEdit"
      :selectedFieldName="selectedFieldName"
      :dialog="showHarvestExpectationEdit"
      :indirectIndexPayload="indirectIndexPayload"
      :harvestExpectationIndexData="harvestExpectationIndexData"
      @closeDialog="closeModals"
      :distributionIndexData="distributionIndexData"
      @saveDialog="updateIndex($event)"
    />

    <nutritional-balance-edit
      v-if="showNutritionalBalanceEdit"
      :selectedFieldName="selectedFieldName"
      :nutricionalBalanceIndexData="nutricionalBalanceIndexData"
      :dialog="showNutritionalBalanceEdit"
      :indirectIndexPayload="indirectIndexPayload"
      @closeDialog="closeModals"
      @saveDialog="updateIndex($event)"
    />

    <change-colection-point-dialog
      :dialog="showChangeLocationDialog"
      @closeDialog="() => (showChangeLocationDialog = false)"
      @changeFile="changePointFile"
    />
  </v-dialog>
</template>

<script>
import Management from "@/domain/management/management";
import IndirectIndex from "@/domain/indexes/indirect-indexes/indirect-indexes";

import CustomLabel from "@/components/texts/CustomLabel";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";

import DecisionIndexEdit from "./DecisionIndexEdit";
import SuccessIndexEdit from "./SuccessIndexEdit";
import DistributionIndexEdit from "./DistributionIndexEdit";
import HarvestExpectationEdit from "./HarvestExpectationEdit";
import NutritionalBalanceEdit from "./NutritionalBalanceEdit";
import ChangeColectionPointDialog from "./ChangeColectionPointDialog.vue";
import ValidationMixin from "@/support/utils/validation.mixin";

export default {
  name: "ManagementRecordPointEdit",
  mixins: [ValidationMixin],
  components: {
    DecisionIndexEdit,
    SuccessIndexEdit,
    DistributionIndexEdit,
    HarvestExpectationEdit,
    NutritionalBalanceEdit,
    CustomLabel,
    SimpleSelect,
    ChangeColectionPointDialog,
  },
  props: {
    managementIndexes: {
      type: Array,
      default: () => [],
    },

    collectPointId: {
      type: Number,
      default: 0,
    },

    dialog: {
      type: Boolean,
      default: false,
    },

    fieldSelected: {
      type: Number,
    },

    selectedFieldId: {
      type: Number,
    },

    selectedFieldName: {
      type: String,
    },

    producerId: {
      type: Number,
    },

    harvest: {
      type: Number,
    },

    farmId: {
      type: Number,
    },

    cultureId: {
      type: Number,
    },

    fieldsByFarm: {
      type: Array,
      default: () => [],
    },

    file: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      payload: {},
      fields: [],
      nutricionalBalanceIndexData: [
        {
          data1: 0,
          data2: 0,
          grade: 0,
        },
      ],
      decisionIndexData: {},
      distributionIndexData: [
        {
          data1: 0,
          data2: 0,
          data3: 0,
          data4: 0,
          data5: 0,
          grade: 0,
        },
        {
          data1: 0,
          data2: 0,
          data3: 0,
          data4: 0,
          data5: 0,
        },
        {
          data1: 0,
          data2: 0,
          data3: 0,
          data4: 0,
          data5: 0,
        },
      ],
      harvestExpectationIndexData: [
        {
          data1: 0,
          data2: 0,
          data3: 0,
          data4: 0,
          data5: 0,
          grade: 0,
        },
        {
          data1: 0,
          data2: 0,
          data3: 0,
          data4: 0,
          data5: 0,
        },
        {
          data1: 0,
          data2: 0,
          data3: 0,
          data4: 0,
          data5: 0,
        },
      ],

      successIndexData: [
        {
          data1: 0,
          data2: 0,
          grade: 0,
        },
        {
          data1: 0,
          data2: 0,
        },
        {
          data1: 0,
          data2: 0,
        },
      ],

      fieldId: "",
      managementRecordService: null,
      grades: [
        "N/A",
        "6.0",
        "6.5",
        "7.0",
        "7.5",
        "8.0",
        "8.5",
        "9.0",
        "9.5",
        "10.0",
      ],
      selectedIndex: [],
      indirectIndexPayload: "",

      decisionIndex: "",
      decisionIndexPlantaMetro: "",
      succesIndex: "",
      distributionIndex: "",
      harvestExpectationIndex: "",
      newFile: {},

      showDecisionIndexEdit: false,
      showSuccessIndexEdit: false,
      showDistributionIndexEdit: false,
      showHarvestExpectationEdit: false,
      showNutritionalBalanceEdit: false,
      showChangeLocationDialog: false,
    };
  },

  methods: {
    close() {
      this.$emit("closeModal");
    },

    updateIndex($event) {
      if ($event[2] && $event[3]) {
        $event[2].collection_point_id = this.managementIndexes.filter(
          (index) => {
            if (index.name.toLowerCase() == $event[3].toLowerCase())
              return index.name.toLowerCase() == $event[3].toLowerCase();
          }
        )[0].id;
        IndirectIndex.saveData($event[2]).then(() => {
          this.getIndexData($event[3]);
        });
      }

      this.selectedIndex[$event[0]] = $event[1]?.toString();
      this.showDecisionIndexEdit = false;
      this.showSuccessIndexEdit = false;
      this.showDistributionIndexEdit = false;
      this.showHarvestExpectationEdit = false;
      this.showNutritionalBalanceEdit = false;
    },

    updateDecisionIndex($event) {
      if ($event[2] && $event[3]) {
        $event[2].collection_point_id = this.managementIndexes.filter(
          (index) => {
            if (index.name.toLowerCase() == $event[3].toLowerCase())
              return index.name.toLowerCase() == $event[3].toLowerCase();
          }
        )[0].id;
        IndirectIndex.saveData($event[2]).then(() => {
          this.getIndexData($event[3]);
        });
      }

      this.selectedIndex[$event[0]] = $event[1]?.toString();
      this.showDecisionIndexEdit = false;
    },

    getIndexData(str) {
      const indirectIndex = this.managementIndexes.filter((index) => {
        if (!index.name) return false;

        return index.name.toLowerCase() == str.toLowerCase();
      })[0];

      if (!indirectIndex) return;

      const id = indirectIndex.id;

      IndirectIndex.getData(id).then((res) => {
        if (str == "Índice de decisão") {
          if (res?.length > 0) {
            this.decisionIndexData = res;
          }
          let dif = [];
          let grade = [];
          let plantaMetro = [];
          this.decisionIndexData.forEach((decisionIndex, i) => {
            const data1 = Number(decisionIndex.data1) || 0;
            const data2 = Number(decisionIndex.data2) || 0;
            plantaMetro[i] = data1 != 0 ? data1 : undefined;

            if (data1 != 0 && data2 != 0) {
              dif[i] = Math.abs(data1 - data2);
              grade[i] = Math.abs((dif[i] - 100) / 10);
            }
          });

          grade = grade.filter((n) => n);
          this.decisionIndex = `${(
            grade.reduce((s, n) => {
              let soma = s;
              if (n) soma += n;
              return soma;
            }) / grade.length
          ).toFixed(1)}`;

          plantaMetro = plantaMetro.filter((n) => n);
          this.decisionIndexPlantaMetro = `${(
            plantaMetro.reduce((s, n) => {
              let soma = s;
              if (n) soma += n;
              return soma;
            }) / plantaMetro.length
          ).toFixed(1)}`;

          this.decisionIndex = `${(
            grade.reduce((s, n) => {
              let soma = s;
              if (n) soma += n;
              return soma;
            }) / grade.length
          ).toFixed(1)}`;

          this.selectedIndex[id] = this.decisionIndex;

          this.decisionIndexData.forEach((value, index) => {
            value.grade = grade[index];
          });

          this.decisionIndexData.push(this.decisionIndex);
        } else if (str == "Índice de Distribuição") {
          let grade = 0;

          if (res?.length > 0) {
            this.distributionIndexData = res;
            let dif1 = 0,
              dif2 = 0,
              dif3 = 0;

            if (
              this.distributionIndexData[0].data3 != 0 &&
              this.distributionIndexData[0].data4 != 0 &&
              this.distributionIndexData[0].data5 != 0
            )
              dif1 =
                (100 -
                  (((this.distributionIndexData[0].data3 || 0 - 1) *
                    this.distributionIndexData[0].data4 || 0) /
                    100 +
                    ((this.distributionIndexData[0].data3 || 0 - 1) *
                      this.distributionIndexData[0].data5 || 0) /
                      100)) /
                10;

            if (
              this.distributionIndexData[1].data3 != 0 &&
              this.distributionIndexData[1].data4 != 0 &&
              this.distributionIndexData[1].data5 != 0
            )
              dif2 =
                (100 -
                  (((this.distributionIndexData[1].data3 || 0 - 1) *
                    this.distributionIndexData[1].data4 || 0) /
                    100 +
                    ((this.distributionIndexData[1].data3 || 0 - 1) *
                      this.distributionIndexData[1].data5 || 0) /
                      100)) /
                10;

            if (
              this.distributionIndexData[2].data3 != 0 &&
              this.distributionIndexData[2].data4 != 0 &&
              this.distributionIndexData[2].data5 != 0
            )
              dif3 =
                (100 -
                  (((this.distributionIndexData[2].data3 || 0 - 1) *
                    this.distributionIndexData[2].data4 || 0) /
                    100 +
                    ((this.distributionIndexData[2].data3 || 0 - 1) *
                      this.distributionIndexData[2].data5 || 0) /
                      100)) /
                10;

            const difs = [dif1, dif2, dif3].filter((dif) => dif != 0);

            grade = `${(
              difs.reduce((s, n) => {
                let soma = s;
                if (n) soma += n;
                return soma;
              }) / difs.length
            ).toFixed(1)}`;

            this.distributionIndex = grade;
            this.selectedIndex[id] = this.distributionIndex;
          }
        } else if (str == "Índice de Acerto") {
          if (res?.length > 0) {
            this.successIndexData = res;
          }

          let grade = [];

          this.successIndexData.forEach((succesIndex, i) => {
            if (Number(succesIndex.data2) !== 0)
              grade[i] = Number(succesIndex.data3);
          });

          grade = grade.filter((n) => n);
          this.succesIndex = `${(
            grade.reduce((s, n) => {
              let soma = s;
              if (n) soma += n;
              return soma;
            }) / grade.length
          ).toFixed(1)}`;

          this.succesIndex = this.succesIndex > 6 ? this.succesIndex : 6;

          this.selectedIndex[id] = this.succesIndex;
        } else if (str == "Expectativa de Colheita") {
          if (res?.length > 0) {
            this.harvestExpectationIndexData = res;

            let numberPlants = [];
            let numberPods = [];
            let numberGrains = [];

            this.harvestExpectationIndexData.forEach((item) => {
              if (item.data1 != 0) numberPlants.push(item.data1);
              if (item.data3 != 0) numberPods.push(item.data3);
              if (item.data4 != 0) numberGrains.push(item.data4);
            });

            if (
              numberPlants.length === 0 ||
              numberPods.length === 0 ||
              numberGrains.length === 0
            ) {
              this.harvestExpectationIndex = "0.0";
              this.selectedIndex[id] = this.harvestExpectationIndex;
              return;
            }

            const resultNumberPlants = `${(
              numberPlants.reduce((s, n) => {
                const soma = Number(s);
                return soma + Number(n);
              }) / numberPlants.length
            ).toFixed(2)}`;

            const resultNumberPods = `${(
              numberPods.reduce((s, n) => {
                const soma = Number(s);
                return soma + Number(n);
              }) / numberPods.length
            ).toFixed(2)}`;

            const resultNumberGrains = `${(
              numberGrains.reduce((s, n) => {
                const soma = Number(s);
                return soma + Number(n);
              }) / numberGrains.length
            ).toFixed(2)}`;

            let harvestExpectation =
              (resultNumberPlants *
                (resultNumberPods *
                  resultNumberGrains *
                  (this.harvestExpectationIndexData[0].data5 / 1000 / 1000)) *
                (10000 / this.harvestExpectationIndexData[0].data2)) /
              60;

            harvestExpectation = parseFloat(harvestExpectation.toFixed(2));

            this.harvestExpectationIndex = this.scale(harvestExpectation);
            this.selectedIndex[id] = this.harvestExpectationIndex;
          }
        } else if (str == "Índice de Balanço Nutricional") {
          let grade = 0;

          if (res?.length > 0) {
            this.nutricionalBalanceIndexData = res;

            const ibnGrade = (
              (100 -
                parseFloat(this.nutricionalBalanceIndexData[0].data1 || 0)) /
              10
            ).toFixed(2);
            const nutrientsGrade = (
              (((parseFloat(this.nutricionalBalanceIndexData[0].data2 || 0) *
                100) /
                12) *
                8) /
              75
            ).toFixed(1);
            const result = (
              ((parseFloat(ibnGrade) || 0) +
                (parseFloat(nutrientsGrade) || 0)) /
              2
            ).toFixed(1);

            grade = result;
          }

          this.nutricionalBalanceIndexData[0].grade = grade;
        }
      });
    },

    openDecisionIndexEdit(index) {
      this.indirectIndexPayload = index;
      this.showDecisionIndexEdit = true;
    },

    openSuccessIndexEdit(index) {
      this.indirectIndexPayload = index;
      this.showSuccessIndexEdit = true;
    },

    openDistributionIndexEdit(index) {
      this.indirectIndexPayload = index;
      this.showDistributionIndexEdit = true;
    },

    openHarvestExpectation(index) {
      this.indirectIndexPayload = index;
      this.showHarvestExpectationEdit = true;
    },

    openNutritionalBalanceEdit(index) {
      this.indirectIndexPayload = index;
      this.showNutritionalBalanceEdit = true;
    },

    closeModals() {
      this.showDecisionIndexEdit = false;
      this.showSuccessIndexEdit = false;
      this.showDistributionIndexEdit = false;
      this.showHarvestExpectationEdit = false;
      this.showNutritionalBalanceEdit = false;
    },

    closeDecisionIndexModal() {
      this.showDecisionIndexEdit = false;
    },

    update() {
      this.payload.harvest = this.harvest;
      this.payload.producer_id = this.producerId;
      this.payload.farm_id = this.farmId;
      this.payload.culture_id = this.cultureId;
      this.payload.is_active = true;
      this.payload.field_id = this.fieldId;
      this.payload.file = null;

      if (this.newFile.base64) this.payload.file = this.newFile.base64;

      this.selectedIndex = this.selectedIndex.map((item) => {
        if (item == "N/A") item = null;
        return item;
      });

      Object.assign(this.payload.indexes, this.selectedIndex);

      this.managementRecordService
        .updateCollectionPoint(this.payload)
        .then(() => {
          this.close();
          this.$emit("closeDialog");

          this.$toasted.show("Ponto de coleta atualizado com sucesso.", {
            type: "success",
          });
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },

    scale(harvestExpectation) {
      let value = "0.0";

      switch (true) {
        case harvestExpectation < 45:
          value = "6.0";
          break;

        case harvestExpectation >= 45 && harvestExpectation <= 50:
          value = "6.5";
          break;

        case harvestExpectation >= 50 && harvestExpectation <= 55:
          value = "7.0";
          break;

        case harvestExpectation >= 55 && harvestExpectation <= 60:
          value = "7.5";
          break;

        case harvestExpectation >= 60 && harvestExpectation <= 65:
          value = "8.0";
          break;

        case harvestExpectation >= 65 && harvestExpectation <= 70:
          value = "8.5";
          break;

        case harvestExpectation >= 70 && harvestExpectation <= 75:
          value = "9.0";
          break;

        case harvestExpectation >= 75 && harvestExpectation <= 80:
          value = "9.5";
          break;

        case harvestExpectation > 80:
          value = "10";
          break;

        default:
          break;
      }

      return value;
    },

    changePointFile(file) {
      const newFile = {
        name: file.name,
        url: "",
        base64: file.base64,
      };

      this.newFile = Object.assign({}, newFile);
    },

    async downloadFile() {
      const loader = this.$loading.show();
      try {
        const data = await this.managementRecordService.getPointFile(
          this.selectedFieldId
        );

        const blob = new Blob([data], { type: "application/gtm" });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${this.selectedFieldName}.gtm`;
        link.click();
        URL.revokeObjectURL(link.href);

        loader.hide();
      } catch (err) {
        console.error(err);
        loader.hide();
      }
    },
  },

  watch: {
    fieldSelected(newValue) {
      this.fields = this.managementIndexes.fields_by_farm.data;
      this.payload.field_id = newValue;

      this.managementIndexes.forEach((item) => {
        this.selectedIndex[item.id] = item.grade;
      });
    },
  },

  beforeMount() {
    this.getIndexData("Índice de decisão");
    this.getIndexData("Índice de Distribuição");
    this.getIndexData("Índice de Acerto");
    this.getIndexData("Expectativa de Colheita");
    this.getIndexData("Índice de Balanço Nutricional");
    this.managementRecordService = Management;
    this.payload = Management.newData();
    this.fieldId = this.selectedFieldId;

    this.managementIndexes.forEach((item) => {
      this.selectedIndex[item.id] = item.grade;
    });
  },
};
</script>

<style scoped>
.custom-container {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}

.section-title {
  font-size: 22px;
}
</style>
