import ContractsService from "./contracts.service";

class Contract {
  constructor(payload = {}) {
    Object.assign(this, {
      ...Contract.build(payload),
    });
  }

  static build(
    {
      id          = '',
      status      = false,
      producer_id = '',
      record_id   = '',
      index_id    = '',
      index       = {},
    }
  ) {
    return {
      id,
      status,
      producer_id,
      record_id,
      index_id,
      index,
    };
  }

  static newData(payload) {
    return new Contract(payload);
  }

  static storeOrUpdate(payload) {
    return new Promise((resolve, reject) => {
      ContractsService.storeOrUpdate(payload).then(
        (success) => {
          resolve(success);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }

  static enabledIndexesByFertilityAndProducer(producerId) {
    return new Promise((resolve, reject) => {
      ContractsService
      .indexesByFertilityAndProducer(producerId, {status: 1})
      .then(
        (success) => {
          const enabledIndexes = success;
          enabledIndexes.data  = enabledIndexes.data.data.map(item => this.newData(item));
          resolve(success);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }

  static enabledIndexesByFinancialAndProducer(producerId) {
    return new Promise((resolve, reject) => {
      ContractsService
      .indexesByFinancialAndProducer(producerId, {status: 1})
      .then(
        (success) => {
          const enabledIndexes = success;
          enabledIndexes.data  = enabledIndexes.data.data.map(item => this.newData(item));
          resolve(success);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }

  static enabledIndexesByManagementAndProducer(producerId) {
    return new Promise((resolve, reject) => {
      ContractsService
      .indexesByManagementAndProducer(producerId, {status: 1})
      .then(
        (success) => {
          const enabledIndexes = success;
          enabledIndexes.data  = enabledIndexes.data.data.map(item => this.newData(item));
          resolve(success);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }
}

export default Contract;
