import RecordsService from "./records.service";

class Record {
    constructor(payload = {}) {
        Object.assign(this, {
            ...Record.build(payload),
        });
    }

    static build(
        {
            id = '',
            culture_id = 0,
            type = '',
            culture  = {},
            phases  = {},
            contracts  = {},
            harvestIql = '',
            postFloweringIql = '',
            postPlantingIql = '',
            managementFarmIql = '',
        }
    ) {
        return {
            id,
            culture_id,
            type,
            culture,
            phases,
            contracts,
            harvestIql,
            postFloweringIql,
            postPlantingIql,
            managementFarmIql,
        };
    }

    static newData(payload) {
        return new Record(payload);
    }

    static list(params) {
        return new Promise((resolve, reject) => {
            RecordsService
                .list(params)
                .then((success) => {
                    const {data} = success;
                    const list = Object.assign({}, {...data});
                    list.data = data.data.map(item => this.newData(item));
                    resolve(list);
                }, (error) => {
                    reject(error);
                })
        });
    }
    static all(params) {
        return new Promise((resolve, reject) => {
            RecordsService
                .all(params)
                .then((success) => {
                    const {data} = success;
                    const list = Object.assign({}, {...data});
                    list.data = data.data.map(item => this.newData(item));
                    resolve(list);
                }, (error) => {
                    reject(error);
                })
        });
    }
    static listIqlManagementIndexes(farmId, harvest) {
        return new Promise((resolve, reject) => {
            RecordsService
                .listIqlManagementIndexes(farmId, harvest)
                .then((success) => {
                    const {data} = success;
                    const list = Object.assign({}, {...data});
                    list.data = data.data.map(item => this.newData(item));
                    resolve(list);
                }, (error) => {
                    reject(error);
                })
        });
    }
    static listByCulture(cultureId) {
        return new Promise((resolve, reject) => {
            RecordsService
                .listByCulture(cultureId)
                .then((success) => {
                    const {data} = success;
                    resolve(data);
                }, (error) => {
                    reject(error);
                })
        });
    }
}

export default Record;
