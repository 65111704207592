<template>
    <v-dialog v-model="dialog" width="500" persistent transition="dialog-bottom-transition">
        <v-card>
            <v-card-title class="form-modal-header">
                <v-btn color="white" text @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <span class="text-h5 white--text">Alterar arquivo de localização</span>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="mt-12">
                <div v-if="!showUpdateDialog" class="mx-auto text-h6 text-center">Tem certeza de que deseja alterar o
                    arquivo de localização?</div>
                <div v-else>
                    <div class="d-flex pt-3 file">
                        <span class="text-upload">
                            <p>Upload</p>
                        </span>

                        <v-file-input 
                            v-model="payload.file" 
                            class="map-file" 
                            dense 
                            outlined 
                            height="48px"
                            placeholder="Escolha um arquivo GTM" 
                            accept=".gtm" 
                            prepend-icon="" 
                            :rules="fileRules" />
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="justify-space-around">
                <v-btn class="text-h6" color="primary" text @click="close">
                    <v-icon left>mdi-close</v-icon>
                    Cancelar
                </v-btn>
                <v-btn v-if="!showUpdateDialog" class="text-h6" text @click="() => showUpdateDialog = true">
                    <v-icon left>mdi-check</v-icon>
                    Alterar
                </v-btn>
                <v-btn v-else class="text-h6" text @click="save" :disabled="!valid">
                    <v-icon left>mdi-content-save</v-icon>
                    Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { toBase64 } from '../../../../support/utils/fileToBase64';
export default {
    props: {
        dialog: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            payload: {
                file: ""
            },
            showUpdateDialog: false,
            valid: false,
            fileRules: [
                (v) => {
                    const fileType = v ? v.name.slice(v.name.lastIndexOf(".")) : null;
                    if (!v) {
                        return "O campo é obrigatório";
                    } else if (fileType !== ".gtm") {
                        return "Somente arquivo gtm é permitido";
                    } else {
                        return true;
                    }
                },
            ],
        }
    },

    watch: {
        payload: {
            handler(newValue) {
                if (newValue.file)
                    this.valid= true;
                else this.valid = false;
            },
            deep: true,
        }
    },

    methods: {
        close() {
            this.$emit("closeDialog");
            this.showUpdateDialog = false
        },

        async save() {
            const file = {
                base64: await toBase64(this.payload.file),
                name: this.payload.file.name
            }

            this.$emit("closeDialog");
            this.$emit("changeFile", file);
            this.showUpdateDialog = false
        },
    },

}
</script>

<style lang="scss" scoped>
::v-deep .col-farm .v-toolbar__content {
    padding: 0;
}

.text-upload {
    display: flex;
    top: 48px;
    width: 87px;
    height: 48px;
    padding: 0.7rem 1.2rem;
    background: #e9ecef 0% 0% no-repeat padding-box;
    border: 1px solid #ced4da;
    border-radius: 1rem 0px 0px 1rem;
}

.text-upload>p {
    text-align: left;
    letter-spacing: 0.11px;
    color: #575757;
}

::v-deep .map-file .v-input__slot>fieldset {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    cursor: pointer;
}

::v-deep .map-file .v-text-field__slot .v-file-input__text {
    cursor: pointer !important;
}

.map-file {
    cursor: pointer !important;
}
</style>