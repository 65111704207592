import http from "@/services/http.service";

const apiUrl = "/management";

class ManagementService {
  static list(params) {
    return http.get(`${apiUrl}`, { params });
  }
  static create(payload) {
    return http.post(`${apiUrl}`, payload);
  }
  static get(farmId, harvest, cultureId) {
    return http.get(`${apiUrl}/farms/${farmId}/harvests/${harvest}/cultures/${cultureId}`);
  }
  static getCollectionPointPhasesManagementIql(farmId, harvest, cultureId, filters) {
    return http.get(`${apiUrl}/collection-points-iql/farms/${farmId}/harvests/${harvest}/cultures/${cultureId}`, { params: { ...filters } });
  }
  static getFarmManagementIql(farmId, harvest, cultureId) {
    return http.get(`${apiUrl}/farm-iql/farms/${farmId}/harvest/${harvest}/cultures/${cultureId}`);
  }
  static createCollectionPoint(payload) {
    return http.post(`${apiUrl}/store-collection-point`, payload);
  }
  static updateCollectionPoint(payload) {
    return http.put(`${apiUrl}/update-collection-point`, payload);
  }
  static deleteCollectionPoint(payload) {
    return http.delete(`${apiUrl}/destroy-collection-point`, payload);
  }
  static getPointFile(pointId) {
    return http.get(`management/colection-point/file/${pointId}`);
  }
}

export default ManagementService;
