<template>
  <v-dialog
      v-model="dialog"
      width="500"
      persistent
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h5 white--text">Novo Ponto de Coleta</span>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="save"> Salvar</v-btn>
      </v-card-title>
      <v-card-text class="mt-12">
        <v-container>
          <v-row>
            <v-col class="col-field">
              <v-toolbar elevation="0" style="background: none">
                <v-toolbar-title class="font-weight-bold">
                  Selecione um Talhão
                </v-toolbar-title>
              </v-toolbar>

              <v-row>
                <v-col>
                  <v-select
                      v-model="payload.field_id"
                      :items="fields"
                      item-text="name"
                      item-value="id"
                      no-data-text="Nenhum resultado encontrado"
                      class="
                      rounded-xl
                      mt-2
                      mr-4
                      bordered-input
                      font-weight-light
                    "
                      label="Talhão"
                      solo
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Management from "@/domain/management/management";

export default {
  name: "ManagementRecordPointAdd",
  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    fields: {
      type: Array,
      default: () => [],
    },

    harvest: {
      type: Number
    },

    producerId: {
      type: Number
    },

    points: {
      type: Array,
      default: () => [],
    },

    payloadIndexes: {
      type: Object,
      default: () => {
      }
    },

    farmId: {
      type: Number
    },

    cultureId: {
      type: Number
    }
  },
  data: () => ({
    payload: {},
    managementRecordService: null,
  }),

  methods: {
    handleSelect(indexId, indexGrade) {
      this.payload.indexes[indexId] = indexGrade;
    },

    close() {
      this.$emit("closeDialog");
    },

    validate() {
      if (!this.payload.field_id) {
        this.$toasted.show("Selecione um talhão!", {
          type: "error",
        });

        return false;
      }

      if (this.payload.field_id) {
        if (this.points.includes(this.payload.field_id)) {
          this.$toasted.show("O talhão selecionado já foi registrado.", {
            type: "error",
          });
          return false;
        }
      }

      return true;
    },

    save() {
      if (this.validate()) {
        const loader = this.$loading.show();

        let lastCollectionPoint = this.points[this.points.length - 1].point;        
        lastCollectionPoint     = lastCollectionPoint.charAt(lastCollectionPoint.length - 1);

        this.payload.harvest = this.harvest;
        this.payload.producer_id = this.producerId;
        this.payload.fields = [];
        this.payload.fields.push(this.payload.field_id);
        this.payload.indexes = this.payloadIndexes;
        this.payload.is_active = true;
        this.payload.names = [
          // 'Ponto de Coleta ' + (this.points.length + 1)
          'Ponto de Coleta ' + (Number(lastCollectionPoint) + 1)
        ];
        this.payload.farm_id = this.farmId;
        this.payload.culture_id = this.cultureId;

        this.managementRecordService
            .createCollectionPoint(this.payload)
            .then(() => {
              loader.hide();
              this.close();

              this.$toasted.show("Ponto de Coleta adicionado com sucesso.", {
                    type: "success",
                  }
              );
            })
            .catch(({response}) => {
              loader.hide();
              const {error} = response.data;
              this.$toasted.show(error, {
                type: "error",
              });
            });
      }
    },
  },

  beforeMount() {
    this.payload = Management.newData();
    this.payload.indexes = this.payloadIndexes;
    this.managementRecordService = Management;
  }
}
</script>

<style scoped>
.custom-container {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}
</style>
