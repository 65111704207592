<template>
  <div>
    <div class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem">Voltar</span>
    </div>

    <v-toolbar class="mt-4 mb-4" elevation="0" style="background: none">
      <v-toolbar-title class="font-weight-bold">
        Registro de Manejo / {{ this.managementRecord.farm.name }} / Detalhes
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <!-- Informações gerais -->
    <v-card class="mb-12 rounded-xl">
      <v-card-title class="yellow-border">
        <v-container class="custom-container">
          <v-row align="center">
            <v-col class="d-flex justify-start">
              <!--              <v-icon @click="closeView()"> mdi-arrow-left</v-icon>&nbsp;-->
              <span class="font-weight-bold">Informações Gerais</span>
              <v-spacer></v-spacer>
              <v-chip class="ma-2" color="blue" outlined pill>
                {{ statusManagement ? "Concluído" : "Em Progresso" }}
              </v-chip>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <p>
                <span><b>Proprietário</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ managementRecord.producer }}
                </span>
              </p>
            </v-col>
            <v-col cols="4">
              <p>
                <span><b>Fazenda</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ this.managementRecord.farm.name }}
                </span>
              </p>
            </v-col>
            <v-col cols="4">
              <p>
                <span><b>Safra</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ managementRecord.harvest }}
                </span>
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <p>
                <span><b>AS Responsável</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ this.managementRecord.responsible_success_advisor }}
                </span>
              </p>
            </v-col>

            <v-col cols="4">
              <p>
                <span><b>Cultura</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ managementRecord.culture }}
                </span>
              </p>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container mt-6">
          <v-row align="center">
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold">Pontos de Coleta</span>
            </v-col>

            <v-spacer></v-spacer>

            <v-btn
              v-if="
                authoritySlug === 'super-admin' || permissions.access_records
              "
              @click="addManagementRecordPoint"
              class="mb-2"
              color="primary"
              dark
            >
              + Adicionar
            </v-btn>
          </v-row>

          <v-row>
            <v-data-table
              style="width: 100%"
              :headers="headers"
              :items="points"
              :hide-default-footer="true"
              no-data-text="Nenhum resultado encontrado"
              :sort-by="['point']"
            >
              <template v-slot:[`item.postPlantingIql`]="{ item }">
                {{ getPointIql(item, "postPlanting") || "0.0" }}
              </template>

              <template v-slot:[`item.postFloweringIql`]="{ item }">
                {{ getPointIql(item, "postFlowering") || "0.0" }}
              </template>

              <template v-slot:[`item.harvestIql`]="{ item }">
                {{ getPointIql(item, "harvest") || "0.0" }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="
                    authoritySlug === 'super-admin' ||
                    permissions.access_records
                  "
                  small
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>

                <v-icon
                  v-if="
                    authoritySlug === 'super-admin' ||
                    permissions.access_records
                  "
                  class="ml-4"
                  small
                  @click="deleteItem(item)"
                >
                  mdi-trash-can-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-row>
        </v-container>

        <v-container class="mt-6">
          <v-row align="center">
            <custom-h2
              style="font-size: 22px"
              :label="
                'Índice de Manejo da Fazenda - Nota: ' +
                (farmIql[2].managementFarmIql !== null
                  ? farmIql[2].managementFarmIql
                  : 'N/A')
              "
            />
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row align="center">
            <v-col cols="12" class="d-flex justify-start pl-0">
              <span class="font-weight-bold section-title"
                >Pós-Plantio - Nota:
                {{ farmIql[1].postPlantingIql || "N/A" }}</span
              >
            </v-col>

            <v-row class="mt-2">
              <template v-for="postPlantingIndex in farmPostPlantingIndexes">
                <v-col
                  v-if="!isSortableIndex(postPlantingIndex.index.name)"
                  :key="postPlantingIndex.index_id"
                  cols="6"
                >
                  <span class="font-weight-light">
                    <p :class="verifyGrade(postPlantingIndex.iql_index)">
                      {{ postPlantingIndex.index.name }}:
                      {{ postPlantingIndex.iql_index || "N/A" }}
                    </p>
                  </span>
                </v-col>
              </template>

              <template v-for="postPlantingIndex in farmPostPlantingIndexes">
                <v-col
                  v-if="isSortableIndex(postPlantingIndex.index.name)"
                  :key="postPlantingIndex.index_id"
                  cols="6"
                >
                  <span class="font-weight-light">
                    <p :class="verifyGrade(postPlantingIndex.iql_index)">
                      {{ postPlantingIndex.index.name }}:
                      {{ postPlantingIndex.iql_index || "N/A" }}
                    </p>
                  </span>
                </v-col>
              </template>
              <v-col cols="6">
                <p>
                  <b>IQL: {{ farmIql[1].postPlantingIql || "N/A" }}</b>
                </p>
              </v-col>
            </v-row>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row align="center">
            <v-col cols="12" class="d-flex justify-start pl-0">
              <span class="font-weight-bold section-title"
                >Pós-Florescimento - Nota:
                {{ farmIql[1].postFloweringIql || "N/A" }}</span
              >
            </v-col>

            <v-row class="mt-2">
              <template v-for="postFloweringIndex in farmPostFloweringIndexes">
                <v-col
                  v-if="!isSortableIndex(postFloweringIndex.index.name)"
                  :key="postFloweringIndex.index_id"
                  cols="6"
                >
                  <span class="font-weight-light">
                    <p :class="verifyGrade(postFloweringIndex.iql_index)">
                      {{ postFloweringIndex.index.name }}:
                      {{ postFloweringIndex.iql_index || "N/A" }}
                    </p>
                  </span>
                </v-col>
              </template>

              <template v-for="postFloweringIndex in farmPostFloweringIndexes">
                <v-col
                  v-if="isSortableIndex(postFloweringIndex.index.name)"
                  :key="postFloweringIndex.index_id"
                  cols="6"
                >
                  <span class="font-weight-light">
                    <p :class="verifyGrade(postFloweringIndex.iql_index)">
                      {{ postFloweringIndex.index.name }}:
                      {{ postFloweringIndex.iql_index || "N/A" }}
                    </p>
                  </span>
                </v-col>
              </template>
              <v-col cols="6">
                <p>
                  <b>IQL: {{ farmIql[1].postFloweringIql || "N/A" }}</b>
                </p>
              </v-col>
            </v-row>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row align="center">
            <v-col cols="12" class="d-flex justify-start pl-0">
              <span class="font-weight-bold section-title"
                >Colheita - Nota: {{ farmIql[1].harvestIql || "N/A" }}</span
              >
            </v-col>

            <v-row class="mt-2">
              <template v-for="postHarvestIndex in farmHarvestIndexes">
                <v-col
                  v-if="!isSortableIndex(postHarvestIndex.index.name)"
                  :key="postHarvestIndex.index_id"
                  cols="6"
                >
                  <span class="font-weight-light">
                    <p :class="verifyGrade(postHarvestIndex.iql_index)">
                      {{ postHarvestIndex.index.name }}:
                      {{ postHarvestIndex.iql_index || "N/A" }}
                    </p>
                  </span>
                </v-col>
              </template>

              <template v-for="postHarvestIndex in farmHarvestIndexes">
                <v-col
                  v-if="isSortableIndex(postHarvestIndex.index.name)"
                  :key="postHarvestIndex.index_id"
                  cols="6"
                >
                  <span class="font-weight-light">
                    <p :class="verifyGrade(postHarvestIndex.iql_index)">
                      {{ postHarvestIndex.index.name }}:
                      {{ postHarvestIndex.iql_index || "N/A" }}
                    </p>
                  </span>
                </v-col>
              </template>
              <v-col cols="6">
                <p>
                  <b>IQL: {{ farmIql[1].harvestIql || "N/A" }}</b>
                </p>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-card-title>
    </v-card>

    <management-record-point-add
      v-if="showManagementRecordPointAdd"
      :dialog="showManagementRecordPointAdd"
      :fields="fields"
      :points="points"
      :harvest="managementRecord.harvest"
      :producerId="getProducerId"
      :farmId="managementRecord.farm.id"
      :cultureId="managementRecord.culture_id"
      :payloadIndexes="payloadIndexesFilter"
      @closeDialog="resetPoint()"
      @closeModal="closeModal()"
    />

    <management-record-point-edit
      v-if="showManagementRecordPointEdit"
      :dialog="showManagementRecordPointEdit"
      :managementIndexes="payloadIndexesFilterEdit"
      :producerId="getProducerId"
      :harvest="managementRecord.harvest"
      :farmId="managementRecord.farm.id"
      :cultureId="managementRecord.culture_id"
      :fieldSelected="managementIndexesByField.field_id"
      :selectedFieldId="selectedFieldId"
      :selectedFieldName="selectedFieldName"
      :fieldsByFarm="fields"
      :file="pointFile"
      @closeDialog="resetPoint()"
      @closeModal="closeModal()"
    />

    <confirm-destroy-dialog
      :title="'este Ponto de Coleta'"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
import LoginService from "@/domain/login/login.service";
import Management from "@/domain/management/management";
import Fields from "@/domain/fields/fields";
import ManagementIndexes from "@/domain/indexes/management-indexes/management-indexes";
import Contracts from "@/domain/contracts/contracts";
import Records from "@/domain/records/records";
import Cultures from "@/domain/cultures/cultures";

import CustomH2 from "@/components/texts/CustomH2";

import ManagementRecordPointAdd from "./ManagementRecordPointAdd.vue";
import ManagementRecordPointEdit from "./ManagementRecordPointEdit.vue";

import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";
import { getItem } from "@/services/local-storage.service";

import ValidationMixin from "@/support/utils/validation.mixin";

export default {
  name: "ManagementRecordDetails",
  mixins: [ValidationMixin],
  components: {
    ManagementRecordPointAdd,
    ManagementRecordPointEdit,
    CustomH2,
    ConfirmDestroyDialog,
  },
  data: () => ({
    authority: true,
    adminId: [1, 2],

    showManagementRecordPointAdd: false,
    showManagementRecordPointEdit: false,

    showManagementRecordFarmEdit: false,

    managementRecordService: null,
    fieldService: null,
    managementIndexService: null,
    contractService: null,
    recordsService: null,
    cultureService: null,

    payload: {},
    farmId: "",
    harvest: "",
    statusManagement: false,
    managementRecord: {},
    phasesIql: {},
    dialog: false,
    managementFields: [],
    pointsByFarm: [],
    points: [],
    fields: [],
    farmIql: [],
    farmPostPlantingIndexes: [],
    farmPostFloweringIndexes: [],
    farmHarvestIndexes: [],
    managementIndexes: [],
    culturesManagement: [],
    managementIndexesByField: {},
    payloadIndexes: [],
    payloadIndexesFilter: {},
    payloadIndexesFilterEdit: [],
    collectionPointData: {},
    selectedFieldId: "",
    selectedFieldName: "",

    authoritySlug: "",
    permissions: "",
    pointFile: "",

    showConfirmDestroyDialog: false,
  }),

  computed: {
    headers() {
      let headers = [
        { text: "Nome", value: "point" },
        { text: "Talhão", value: "field.name" },
        { text: "Pós-plantio", value: "postPlantingIql" },
        { text: "Pós-florescimento", value: "postFloweringIql" },
        { text: "Colheita", value: "harvestIql" },
      ];

      if (
        this.authoritySlug === "super-admin" ||
        this.permissions.access_records
      ) {
        headers.push({ text: "Ações", value: "actions", sortable: false });
      }

      return headers;
    },

    getAS() {
      const record = this.managementRecord;
      const conditional = record.farm && record.farm.data;

      return conditional
        ? record.farm.data.producer.responsible_success_advisor.name
        : "";
    },

    getFarm() {
      const farm = this.managementRecord.farm;

      return JSON.stringify(farm) !== JSON.stringify({}) ? farm : "";
    },

    getProducerId() {
      const record = this.managementRecord;
      return record.farm.producer.id;
    },

    isViewingAdmin() {
      return this.authority;
    },
  },

  methods: {
    getUserLogged() {
      const loader = this.$loading.show();

      LoginService.getUserLogged()
        .then((data) => {
          if (!this.adminId.includes(data.authority_id)) {
            this.authority = false;
          }

          loader.hide();
        })
        .catch(() => {
          location.reload();
          loader.hide();
        });
    },

    async loadManagementRecord() {
      const loader = this.$loading.show();

      await this.managementRecordService
        .get(this.farmId, this.harvest, this.cultureId)
        .then((result) => {
          this.managementRecord = result.data;
        })
        .catch(({ response }) => {
          const { error } = response.data;

          this.$router.push({ name: "management-records" });
          this.$toasted.show(error, {
            type: "error",
          });
        });

      if (!this.managementRecord.indexes) {
        loader.hide();
        this.$router.push({ name: "management-records" });
        return;
      }

      this.getCulturesManagement();

      setTimeout(this.loadEnabledIndexes, 1000);
      setTimeout(this.mapCollectionPoints, 1000);
      setTimeout(this.loadFields, 1000);
      setTimeout(this.loadCollectionPointsPhasesIql, 1000);
      setTimeout(this.loadFarmIql(loader), 1000);
    },

    loadCollectionPointsPhasesIql() {
      this.managementRecordService
        .getCollectionPointPhasesManagementIql(
          this.farmId,
          this.harvest,
          this.cultureId
        )
        .then((result) => {
          this.phasesIql = result.data.points;
        })
        .catch(({ response }) => {
          const { error } = response.data;

          this.$router.push({ name: "management-records" });
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },

    getPointIql(point, phase) {
      const pointIndex = this.points.indexOf(point);
      let iqlPhaseName = "";

      switch (phase) {
        case "postPlanting":
          iqlPhaseName = "postPlantingIql";
          break;
        case "postFlowering":
          iqlPhaseName = "postFloweringIql";
          break;
        case "harvest":
          iqlPhaseName = "harvestIql";
          break;
      }

      const iql = this.phasesIql[pointIndex];

      if (iqlPhaseName === "postPlantingIql" && iql) {
        return (
          iql[0]["postPlantingIql"][0] &&
          iql[0]["postPlantingIql"][0]["iql_phase"]
        );
      }

      if (iqlPhaseName === "postFloweringIql" && iql) {
        return (
          iql[1]["postFloweringIql"][0] &&
          iql[1]["postFloweringIql"][0]["iql_phase"]
        );
      }

      if (iqlPhaseName === "harvestIql" && iql) {
        return iql[2]["harvestIql"][0] && iql[2]["harvestIql"][0]["iql_phase"];
      }
    },

    async loadFarmIql(loader = null) {
      await this.managementRecordService
        .getFarmManagementIql(
          this.farmId,
          this.harvest,
          this.managementRecord.culture_id
        )
        .then((result) => {
          this.farmIql = result.data;

          this.farmPostPlantingIndexes = this.farmIql[0].filter((item) => {
            if (item.index) return item.index.phase.name === "Pós-Plantio";
          });

          this.farmPostFloweringIndexes = this.farmIql[0].filter((item) => {
            if (item.index)
              return item.index.phase.name === "Pós-Florescimento";
          });

          this.farmHarvestIndexes = this.farmIql[0].filter((item) => {
            if (item.index) return item.index.phase.name === "Colheita";
          });

          if (loader) {
            loader.hide();
          }
        })
        .catch(({ response }) => {
          if (loader) {
            loader.hide();
          }

          const { error } = response.data;

          this.$router.push({ name: "management-records" });
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },

    async getCulturesManagement() {
      const loader = this.$loading.show();
      this.culturesManagement = [];

      await this.cultureService.listManagementCultures().then((result) => {
        result.data.map((item) => {
          this.culturesManagement.push(item);
        });
      });

      loader.hide();
    },

    async loadEnabledIndexes() {
      const loader = this.$loading.show();
      this.managementIndexes = [];
      this.payloadIndexes = [];

      await this.contractService
        .enabledIndexesByManagementAndProducer(this.getProducerId)
        .then((result) => {
          loader.hide();
          result.data.map((item) => {
            this.managementIndexes.push(item);
            this.payloadIndexes[item.index.id] = null;
          });
        })
        .catch(({ response }) => {
          loader.hide();

          const { error } = response.data;

          this.$router.push({ name: "management-records" });
          this.$toasted.show(error, {
            type: "error",
          });
        });

      this.culturesManagement = this.culturesManagement.find((item) => {
        return item.name === this.managementRecord.culture;
      });

      let managementIndexesByCulture = [];

      this.culturesManagement.records[0].phases.map((phase) => {
        phase.indexes.map((index) => {
          managementIndexesByCulture.push(index.id);
        });
      });

      this.payloadIndexesFilter = {};

      this.payloadIndexes.filter((value, key) => {
        if (managementIndexesByCulture.includes(key)) {
          this.payloadIndexesFilter[key] = value;
        }
      });

      loader.hide();
    },

    getManagementIndexes() {
      let loader = this.$loading.show();

      this.recordsService
        .listIqlManagementIndexes(
          this.getProducerId,
          this.farmDetails.harvestYearCalculation
        )
        .then((result) => {
          result.data.map((itm) => {
            this.managementIndexes.push(itm);
          });
        });

      loader.hide();
    },

    mapCollectionPoints() {
      this.points = this.managementRecord.indexes;

      this.points = this.removeDuplicates(this.points, "point");

      this.points.sort((a, b) => {
        return (a.point > b.point) - (a.point < b.point);
      });
    },

    removeDuplicates(originalArray, prop) {
      let i;
      const newArray = [];
      const lookupObject = {};

      for (i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
      }

      for (i in lookupObject) {
        newArray.push(lookupObject[i]);
      }
      return newArray;
    },

    loadFields() {
      this.fieldService
        .listByFarm(this.managementRecord.farm.id)
        .then((result) => {
          result.data.map((itm) => {
            this.fields.push(itm);
          });
        });
    },

    /*loadIndexes() {
      const loader = this.$loading.show();
      this.pointsByFarm = [];
      this.managementIndexes = [];
      this.payloadIndexes = {};

      this.contractService
        .enabledIndexesByManagementAndProducer(this.getProducerId)
        .then((result) => {
          loader.hide();
          result.data.map((item) => {
            this.managementIndexes.push(item);
            this.payloadIndexes[item.index.id] = null;
          });
        })
        .catch(({ response }) => {
          loader.hide();

          const { error } = response.data;

          this.$router.push({ name: "management-records" });
          this.$toasted.show(error, {
            type: "error",
          });
        });

      loader.hide();
    },*/

    addManagementRecordPoint() {
      this.showManagementRecordPointAdd = true;
    },

    viewItem(item) {
      this.$router.push({
        name: "management-record-fields-details",
        params: { fieldId: item.field_id, harvests: this.harvest },
      });
    },

    editItem(item) {
      const pointIndex = this.points.indexOf(item);
      this.payloadIndexesFilterEdit = this.managementRecord.indexes.filter(
        (x) => x.point === item.point
      );

      this.pointFile = this.phasesIql[pointIndex].file;
      this.selectedFieldId = item.field ? item.field.id : "";
      this.selectedFieldName = item.field ? item.field.name : "";
      this.showManagementRecordPointEdit = true;
    },

    resetFarm() {
      this.dialog = false;
      this.showManagementRecordFarmAdd = false;
      this.showManagementRecordFarmEdit = false;
      this.loadManagementRecord();
    },

    resetPoint() {
      this.dialog = false;
      this.showManagementRecordPointAdd = false;
      this.showManagementRecordPointEdit = false;
      this.loadManagementRecord();
    },

    closeModal() {
      this.dialog = false;
      this.showManagementRecordPointAdd = false;
      this.showManagementRecordPointEdit = false;
    },

    closeView() {
      this.$router.push({ name: "management-records" });
    },

    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },

    deleteItem(item) {
      this.collectionPointData = {
        data: {
          farm_id: this.managementRecord.farm.id,
          harvest: this.managementRecord.harvest,
          name: item.point,
          culture_id: this.cultureId,
        },
      };

      this.showConfirmDestroyDialog = true;
    },

    async destroy() {
      const loader = this.$loading.show();

      await this.managementRecordService
        .deleteCollectionPoint(this.collectionPointData)
        .then(() => {
          loader.hide();
          this.loadManagementRecord();
          this.showConfirmDestroyDialog = false;
          this.$toasted.show("Ponto de coleta removido com sucesso.", {
            type: "success",
          });
        })
        .catch(() => {
          loader.hide();
          this.showConfirmDestroyDialog = false;
          this.$toasted.show("Ponto de coleta não foi removido.", {
            type: "error",
          });
        });
    },

    verifyGrade(grade) {
      return !grade && "no-grade";
    },
  },

  mounted() {
    this.farmId = this.$route.params.farmId;
    this.harvest = this.$route.params.harvest;
    this.cultureId = this.$route.params.cultureId;
    this.statusManagement = this.$route.params.statusManagement;

    this.loadManagementRecord();
  },

  beforeMount() {
    this.getUserLogged();
    this.payload = Management.newData();
    this.managementRecordService = Management;
    this.fieldService = Fields;
    this.managementIndexService = ManagementIndexes;
    this.contractService = Contracts;
    this.recordsService = Records;
    this.cultureService = Cultures;

    this.authoritySlug = getItem("authoritySlug");
    this.permissions = JSON.parse(getItem("permissions"));
  },
};
</script>

<style scoped>
.custom-container {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}

.no-grade {
  color: red;
  font-weight: bold;
}

.section-title {
  font-size: 22px;
}

::v-deep .v-toolbar__content {
  padding: 0 !important;
}
</style>
