import http from "@/services/http.service";

const apiUrl = "/contracts";

class ContractsService {
  static storeOrUpdate(payload) {
    return http.post(`${apiUrl}`, payload);
  }

  static indexesByFertilityAndProducer(producerId, params) {
    return http.get(`fertility-indexes/producers/${producerId}`, {params});
  }

  static indexesByFinancialAndProducer(producerId, params) {
    return http.get(`financial-indexes/producers/${producerId}`, {params});
  }

  static indexesByManagementAndProducer(producerId, params) {
    return http.get(`management-indexes/producers/${producerId}`, {params});
  }
}

export default ContractsService;
