import {clearLocalStorage, saveToken, saveUser,} from "@/services/local-storage.service";
import http from "@/services/http.service";

const endpoint = "/login";

class UserService {
    static login(user) {
        return new Promise((resolve, reject) =>
            http.post(endpoint, user).then(
                ({data}) => {
                    saveToken(data);
                    // saveUser(data);
                    this.saveUser();
                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            )
        );
    }

    static refresh() {
        return new Promise((resolve, reject) =>
            http.post("refresh").then(
                ({data}) => {
                    saveToken(data);
                    saveUser(data);
                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            )
        );
    }

    static saveUser() {
        return new Promise((resolve, reject) =>
            http.post("me").then(
                ({data}) => {
                    saveUser(data);
                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            )
        );
    }

    static getUserLogged() {
        return new Promise((resolve, reject) =>
            http.post("me").then(
                ({data}) => {
                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            )
        );
    }

    static logout() {
        return new Promise((resolve, reject) =>
            http.post(endpoint).then(
                ({data}) => {
                    clearLocalStorage();
                    resolve(data);
                },
                (error) => {
                    clearLocalStorage();
                    reject(error);
                }
            )
        );
    }

    static resetPassword(payload) {
        return new Promise((resolve, reject) =>
            http.post("forgot-password", payload).then(
                ({data}) => {
                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            )
        );
    }

    static changePassowrd(payload) {
        return new Promise((resolve, reject) =>
            http.post("users/change-password", payload).then(
                ({status}) => {
                    resolve(status);
                },
                (error) => {
                    reject(error);
                }
            )
        );
    }

    static getLoggedUserPermissions() {
        return new Promise((resolve, reject) =>
            http.post('logged-user/permissions').then(
                ({data}) => {
                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            )
        );
    }
}

export default UserService;
