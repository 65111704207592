import ManagementIndexesService from './management-indexes.service';

class ManagementIndex {
  constructor(payload = {}) {
    Object.assign(this, {
      ...ManagementIndex.build(payload),
    });
  }

  static build(
    {
      id       = '',
      name     = '',
      phase_id = '',
      phase    = {}
    }
  ) {
    return {
      id,
      name,
      phase_id,
      phase
    };
  }

  static newData(payload) {
    return new ManagementIndex(payload);
  }

  static all() {
    return new Promise((resolve, reject) => {
        ManagementIndexesService
        .all()
        .then(
          success => {
            const harvestIndexes = success;
            harvestIndexes.data  = harvestIndexes
              .data
              .map(item => this.newData(item));
            resolve(harvestIndexes);
          },

          error => {
            reject(error);
          }
        );
    });
  }
}

export default ManagementIndex;
