import http from "@/services/http.service";

const apiUrl = "/records";

class RecordsService {
  static list(params) {
    return http.get(`${apiUrl}`, { params });
  }
  static all(params) {
    return http.get(`${apiUrl}-all`, { params });
  }
  static listIqlManagementIndexes(farmId, harvest) {
    return http.get(`iql-management-indexes/farms/${farmId}/harvest/${harvest}`);
  }
  static listByCulture(cultureId) {
    return http.get(`${apiUrl}/cultures/${cultureId}`);
  }
}

export default RecordsService;
