import ManagementRecordsService from "./management-records.service";

class ManagementRecord {
    constructor(payload = {}) {
        Object.assign(this, {
            ...ManagementRecord.build(payload)
        });
    }

    static build(
        {
            id = '',
            grade = '',
            harvest = '',
            field_id = '',
            field = {},
            farm_id = '',
            farm = {},
            indexes = {},
            producer_id = '',
            producer = '',
            farmDetails = {},
            farmsByProducer = {},
            fields_by_farm = {},
            harvestIql = {},
            postFloweringIql = {},
            postPlantingIql = {},
            culture = '',
            recordDate = '',
            recordIql = '',
            iql_record_field = '',
            created_at = '',
            updated_at = ''
        }
    ) {
        return {
            id,
            grade,
            harvest,
            field_id,
            field,
            farm_id,
            farm,
            indexes,
            producer_id,
            producer,
            farmDetails,
            farmsByProducer,
            fields_by_farm,
            harvestIql,
            postFloweringIql,
            postPlantingIql,
            culture,
            recordDate,
            recordIql,
            iql_record_field,
            created_at,
            updated_at
        }
    }

    static newData(payload) {
        return new ManagementRecord(payload);
    }

    static list(params) {
        return new Promise((resolve, reject) => {
            ManagementRecordsService
                .list(params)
                .then(
                    success => {
                        const data = {...success};
                        const list = Object.assign({}, {data});
                        list.data = data.data.map(item => this.newData(item));
                        resolve(list);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static create(payload) {
        return new Promise((resolve, reject) => {
            ManagementRecordsService
                .create(payload)
                .then(
                    success => {
                        resolve(success);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static edit(payload) {
        return new Promise((resolve, reject) => {
            ManagementRecordsService
                .edit(payload)
                .then(
                    success => {
                        resolve(success);
                    },

                    error => {
                        reject(error);
                    }
                );
        })
    }

    static get(farmId, harvest) {
        return new Promise((resolve, reject) => {
            ManagementRecordsService
                .get(farmId, harvest)
                .then(
                    success => {
                        const fertilityRecord = this.newData(success.data.data);
                        resolve(fertilityRecord);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getIndexesByManagementAndProducer(producerId) {
        return new Promise((resolve, reject) => {
            ManagementRecordsService
                .getIndexesByManagementAndProducer(producerId)
                .then(
                    success => {
                        const data = {...success};
                        const list = Object.assign({}, {data});
                        list.data = data.data.map(item => this.newData(item));
                        resolve(list);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getIqlPhasesManagementCollectionPoint(producerId, harvest) {
        return new Promise((resolve, reject) => {
            ManagementRecordsService
                .getIqlPhasesManagementCollectionPoint(producerId, harvest)
                .then((success) => {
                    const {data} = success;
                    const list = Object.assign({}, {...data});
                    list.data = data.data.map(item => this.newData(item));
                    resolve(list);
                }, (error) => {
                    reject(error);
                })
        });
    }
}

export default ManagementRecord;
