<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h5 white--text"
          >Editar Índice de Decisão - {{ selectedFieldName || "" }}</span
        >
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="update"> Salvar </v-btn>
      </v-card-title>
      <v-card-text class="mt-12">
        <v-container>
          <v-row>
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold section-title"
                >Índice de Decisão - {{ decisionIndexData[3] || "N/A" }}</span
              >
            </v-col>
          </v-row>
        </v-container>
        <v-spacer></v-spacer>

        <v-container>
          <v-row>
            <v-col>Repetição 01</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="6">
              <custom-label
                label="Número de Sementes / Metros Linear - Recomendado"
              />
              <simple-text-field
                type="number"
                v-model="firstRepetition.number_seeds_recommended"
                @input="calcFirstRepetition"
              />
            </v-col>

            <v-col cols="6">
              <custom-label
                label="Número de Sementes / Metros Linear - Utilizado"
              />
              <simple-text-field
                type="number"
                v-model="firstRepetition.number_seeds_used"
                @input="calcFirstRepetition"
              />
            </v-col>

            <v-col>
              <custom-label label="Diferença" />
              <simple-text-field v-model="firstRepDiff" :disabled="true" />
            </v-col>

            <v-col>
              <custom-label label="Resultado" />
              <simple-text-field v-model="firstRepResult" :disabled="true" />
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col>Repetição 02</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="6">
              <custom-label
                label="Número de Sementes / Metros Linear - Recomendado"
              />
              <simple-text-field
                type="number"
                v-model="secondRepetition.number_seeds_recommended"
                @input="calcSecondRepetition"
              />
            </v-col>

            <v-col cols="6">
              <custom-label
                label="Número de Sementes / Metros Linear - Utilizado"
              />
              <simple-text-field
                type="number"
                v-model="secondRepetition.number_seeds_used"
                @input="calcSecondRepetition"
              />
            </v-col>

            <v-col>
              <custom-label label="Diferença" />
              <simple-text-field v-model="secondRepDiff" :disabled="true" />
            </v-col>

            <v-col>
              <custom-label label="Resultado" />
              <simple-text-field v-model="secondRepResult" :disabled="true" />
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col>Repetição 03</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="6">
              <custom-label
                label="Número de Sementes / Metros Linear - Recomendado"
              />
              <simple-text-field
                type="number"
                v-model="thirdRepetition.number_seeds_recommended"
                @input="calcThirdRepetition"
              />
            </v-col>

            <v-col cols="6">
              <custom-label
                label="Número de Sementes / Metros Linear - Utilizado"
              />
              <simple-text-field
                type="number"
                v-model="thirdRepetition.number_seeds_used"
                @input="calcThirdRepetition"
              />
            </v-col>

            <v-col>
              <custom-label label="Diferença" />
              <simple-text-field v-model="thirdRepDiff" :disabled="true" />
            </v-col>

            <v-col>
              <custom-label label="Resultado" />
              <simple-text-field v-model="thirdRepResult" :disabled="true" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ManagementRecords from "@/domain/records/management-records/management-records";
import CustomLabel from "@/components/texts/CustomLabel";
import SimpleTextField from "@/components/inputs/SimpleTextField";

export default {
  name: "DecisionIndexEdit",
  components: { CustomLabel, SimpleTextField },
  props: {
    managementIndexes: {
      type: Object,
      default: () => {},
    },

    dialog: {
      type: Boolean,
      default: false,
    },

    decisionIndexData: {
      type: Array,
      default: () => [],
    },

    fieldSelected: {
      type: Number,
    },

    selectedFieldName: {
      type: String,
    },

    indirectIndexPayload: {
      type: Number,
    },

    oldValue: {
      type: String,
    },
  },

  data() {
    return {
      payload: {},
      firstRepetition: {},
      secondRepetition: {},
      thirdRepetition: {},
      fieldId: "",
      harvest: "",
      managementRecordService: null,
      grades: ["6.0", "6.5", "7.0", "7.5", "8.0", "8.5", "9.0", "9.5", "10.0"],
      selectedIndex: [],
      data: {
        repetitions: { 0: 1, 1: 2, 2: 3 },
        collection_point_id: 0,
      },

      firstRepResult: "",
      firstRepDiff: "",
      secondRepResult: "",
      secondRepDiff: "",
      thirdRepResult: "",
      thirdRepDiff: "",
      finalResult: [],
    };
  },

  methods: {
    close() {
      this.$emit("closeDialog");
    },

    save() {
      const data1 = {
        data1: {
          0: parseFloat(this.firstRepetition.number_seeds_recommended) || 0,
          1: parseFloat(this.secondRepetition.number_seeds_recommended) || 0,
          2: parseFloat(this.thirdRepetition.number_seeds_recommended) || 0,
        },
      };
      const data2 = {
        data2: {
          0: parseFloat(this.firstRepetition.number_seeds_used) || 0,
          1: parseFloat(this.secondRepetition.number_seeds_used) || 0,
          2: parseFloat(this.thirdRepetition.number_seeds_used) || 0,
        },
      };
      this.$emit("saveDialog", [
        this.indirectIndexPayload,
        this.finalResult.average,
        Object.assign(this.data, data1, data2),
        "Índice de decisão",
      ]);
    },

    calcFirstRepetition() {
      this.firstRepDiff = Math.abs(
        this.firstRepetition.number_seeds_used -
          this.firstRepetition.number_seeds_recommended
      );

      if (
        this.firstRepetition.number_seeds_used == 0 &&
        this.firstRepetition.number_seeds_recommended == 0
      )
        this.firstRepResult = 0;
      else this.firstRepResult = Math.abs(this.firstRepDiff - 100) / 10;
    },

    calcSecondRepetition() {
      this.secondRepDiff = Math.abs(
        (parseFloat(this.secondRepetition.number_seeds_used) || 0) -
          (parseFloat(this.secondRepetition.number_seeds_recommended) || 0)
      );
      if (
        this.secondRepetition.number_seeds_used == 0 &&
        this.secondRepetition.number_seeds_recommended == 0
      )
        this.secondRepResult = 0;
      else this.secondRepResult = Math.abs(this.secondRepDiff - 100) / 10
    },

    calcThirdRepetition() {
      this.thirdRepDiff = Math.abs(
        (parseFloat(this.thirdRepetition.number_seeds_used) || 0) -
          (parseFloat(this.thirdRepetition.number_seeds_recommended) || 0)
      );
      if (
        this.thirdRepetition.number_seeds_used == 0 &&
        this.thirdRepetition.number_seeds_recommended == 0
      )
        this.thirdRepResult = 0;
      else this.thirdRepResult = Math.abs(this.thirdRepDiff - 100) / 10
    },

    async getFinalResult() {
      this.save();
    },

    update() {
      this.getFinalResult();
    },

    loadPreviousresults() {
      this.firstRepetition.number_seeds_recommended =
        this.decisionIndexData[0].data1;
      this.firstRepetition.number_seeds_used = this.decisionIndexData[0].data2;
      this.secondRepetition.number_seeds_recommended =
        this.decisionIndexData[1].data1;
      this.secondRepetition.number_seeds_used = this.decisionIndexData[1].data2;
      this.thirdRepetition.number_seeds_recommended =
        this.decisionIndexData[2].data1;
      this.thirdRepetition.number_seeds_used = this.decisionIndexData[2].data2;

      this.calcFirstRepetition();
      this.calcSecondRepetition();
      this.calcThirdRepetition();
    },
  },

  watch: {
    fieldSelected(newValue) {
      this.fields = this.managementIndexes.fields_by_farm.data;
      this.payload.field_id = newValue;

      this.managementIndexes.indexes.forEach((item) => {
        this.selectedIndex[item.id] = item.grade;
      });
    },
  },

  beforeMount() {
    this.managementRecordService = ManagementRecords;
    this.finalResult.average = this.oldValue;
    this.loadPreviousresults();
  },
};
</script>

<style scoped>
.custom-container {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}

.section-title {
  font-size: 22px;
}
</style>
