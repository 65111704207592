import FieldsService from "./fields.service";

class Field {
    constructor(payload = {}) {
        Object.assign(this, {
            ...Field.build(payload),
        });
    }

    static build(
        {
            id = '',
            name = '',
            size = 0,
            is_active = false,
            diseases = []
        }
    ) {
        return {
            id,
            name,
            size,
            is_active,
            diseases
        };
    }

    static newData(payload) {
        return new Field(payload);
    }

    static create(payload) {
        return new Promise((resolve, reject) => {
            FieldsService
                .create(payload)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    static edit(payload = {}) {
        return new Promise((resolve, reject) => {
            FieldsService
                .edit(payload)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    static toggle(id) {
        return new Promise((resolve, reject) => {
            FieldsService
                .toggle(id)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    static listByFarm(farmId) {
        return new Promise((resolve, reject) => {
            FieldsService.listByFarm(farmId).then(
                (success) => {
                    const fields = success;
                    fields.data = fields.data.data.map((item) => this.newData(item));

                    resolve(fields);
                },

                (error) => {
                    reject(error);
                }
            );
        });
    }

    static listWithoutFertilityIndexes(farmId, harvest) {        
        return new Promise((resolve, reject) => {
            FieldsService.listWithoutFertilityIndexes(farmId, harvest).then(
                (success) => {
                    const fields = success;
                    fields.data = fields.data.data.map((item) => this.newData(item));
                    resolve(fields);
                },

                (error) => {
                    reject(error);
                }
            );
        });
    }

    static delete(payload) {
        return new Promise((resolve, reject) => {
            FieldsService
            .delete(payload)
            .then(success => {
                resolve(success);
            }, error => {
                reject(error);
            });
        });
    }
}

export default Field;
