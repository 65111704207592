export default {
  methods: {
    isSortableIndex(value) {
      return (
        value === "Nota do Gerente" ||
        value === "Nota do Proprietário" ||
        value === "Nota da Drakkar"
      );
    },
  },
};
