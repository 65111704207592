<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h5 white--text">Editar Balanço Nutricional - {{ selectedFieldName || '' }}</span>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="update"> Salvar </v-btn>
      </v-card-title>
      <v-card-text class="mt-12">
        <v-container>
          <v-row>
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold section-title">Balanço Nutricional - {{ nutricionalBalanceIndexData[0].grade || 'N/A' }}</span>
            </v-col>
          </v-row>
        </v-container>
        <v-spacer></v-spacer>

        <v-container>
          <v-row>
            <v-col>Repetição 01</v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container my-2">
          <v-row justify="center" align="center">
            <v-col cols="4">
              <custom-label label="IBN Laudo"/>
              <simple-text-field type="number" v-model="firstRepetition.ibnReport" :placeholder="nutricionalBalanceIndexData[0].data1 == 0 ? '' : nutricionalBalanceIndexData[0].data1" @input="calcFirstRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Número de Nutrientes Equilibrados"/>
              <simple-text-field type="number" v-model="firstRepetition.nutrients" :placeholder="nutricionalBalanceIndexData[0].data2 == 0 ? '' : nutricionalBalanceIndexData[0].data2" @input="calcFirstRepetition" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Nota IBN"/>
              <simple-text-field type="number" :disabled="true" v-model="firstRepetition.ibnGrade" />
            </v-col>

            <v-col cols="4">
              <custom-label label="Nota Nutriente em Equlíbrio"/>
              <simple-text-field type="number" :disabled="true" v-model="firstRepetition.nutrientsGrade" />
            </v-col>

            <v-col>
              <custom-label label="Resultado"/>
              <simple-text-field type="number" v-model="firstRepetitionResults.result" :disabled="true"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ManagementRecords from "@/domain/records/management-records/management-records";
import CustomLabel from "@/components/texts/CustomLabel";
import SimpleTextField from "@/components/inputs/SimpleTextField";

// import IndirectIndex from "@/domain/indexes/indirect-indexes/indirect-indexes";

export default {
  name: "NutritionalBalanceEdit",
  components: {CustomLabel, SimpleTextField},
  props: {
    managementIndexes: {
      type: Object,
      default: () => {}
    },

    dialog: {
      type: Boolean,
      default: false
    },

    fieldSelected: {
      type: Number
    },

    nutricionalBalanceIndexData: {
      type: Array,
      default: () => []
    },

    selectedFieldName: {
      type: String,
    },

    indirectIndexPayload: {
      type: Number,
    },

    oldValue: {
      type: String,
    }
  },

  data() {
    return {
      payload: {},
      firstRepetition: {},
      fieldId: '',
      harvest: '',
      managementRecordService: null,
      grades: ['6.0', '6.5', '7.0', '7.5', '8.0', '8.5', '9.0', '9.5', '10.0'],
      selectedIndex: [],
      data: {
        "repetitions": {"0": 1},
        "collection_point_id": 0,
      },
      firstRepetitionResults: {result: null},
      secondRepetitionResults: [],
      thirdRepetitionResults: [],
      finalResult: [],
    }
  },

  methods: {
    close() {
      this.$emit('closeDialog');
    },

    save() {
      const data1 = {data1: {"0": parseFloat(this.firstRepetition.ibnReport) || 0}};
      const data2 = {data2: {"0": parseFloat(this.firstRepetition.nutrients) || 0}};

      this.$emit('saveDialog',
          [this.indirectIndexPayload, this.finalResult, Object.assign(this.data, data1, data2), "Índice de Balanço Nutricional"]);
    },

    calcFirstRepetition() {

      let loader = this.$loading.show();
      
      this.firstRepetition.ibnGrade = ((100 - parseFloat(this.firstRepetition.ibnReport))/10).toFixed(2);
      this.firstRepetition.nutrientsGrade = (((parseFloat(this.firstRepetition.nutrients)*100)/12)*8/75).toFixed(1);
      this.firstRepetitionResults.result = (((parseFloat(this.firstRepetition.ibnGrade) || 0) + (parseFloat(this.firstRepetition.nutrientsGrade) || 0))/2).toFixed(1);

      loader.hide();
      // this.getFinalResult();
    },

    getFinalResult() {
      this.finalResult = this.firstRepetitionResults.result;
    },

    update() {
      this.getFinalResult();
      this.save();
    }
  },

  watch: {
    fieldSelected(newValue) {
      this.fields           = this.managementIndexes.fields_by_farm.data;
      this.payload.field_id = newValue;

      this.managementIndexes.indexes.forEach(item => {
        this.selectedIndex[item.id] = item.grade;
      });
    }
  },

  beforeMount() {
    this.managementRecordService = ManagementRecords;
    this.finalResult = this.oldValue;
  }
}
</script>

<style scoped>
.custom-container {
  border: 1px solid #e3e3e3;
  border-radius:5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}

.section-title {
  font-size: 22px;
}
</style>
