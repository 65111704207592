import ManagementService from "./management.service";

class Management {
    constructor(payload = {}) {
        Object.assign(this, {
            ...Management.build(payload),
        });
    }

    static build(
        {
            id = '',
            farm = {},
            farm_id = '',
            farm_name = '',
            harvest = '',
            culture_id = '',
            culture = '',
            indexes = {},
            producer = {},
            producer_id = '',
            producer_name = '',
            points = {},
            harvestIql = '',
            postFloweringIql = '',
            postPlantingIql = '',
            managementFarmIql = '',
            responsible_success_advisor = '',
            is_active = true,
            status = false
        }
    ) {
        return {
            id,
            farm,
            farm_id,
            farm_name,
            harvest,
            culture_id,
            culture,
            indexes,
            producer,
            producer_id,
            producer_name,
            points,
            harvestIql,
            postFloweringIql,
            postPlantingIql,
            managementFarmIql,
            responsible_success_advisor,
            is_active,
            status
        };
    }

    static newData(payload) {
        return new Management(payload);
    }

    static list(params) {
        return new Promise((resolve, reject) => {
            ManagementService
                .list(params)
                .then((success) => {
                    const {data} = success;
                    const list = Object.assign({}, {...data});
                    list.data = data.data.map(item => this.newData(item));
                    resolve(list);
                }, (error) => {
                    reject(error);
                })
        });
    }

    static create(payload) {
        return new Promise((resolve, reject) => {
            ManagementService
                .create(payload)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    static get(farmId, harvest, cultureId) {
        return new Promise((resolve, reject) => {
            ManagementService
                .get(farmId, harvest, cultureId)
                .then(
                    success => {
                        const management = success;
                        management.data = this.newData(management.data.data);

                        resolve(management);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getCollectionPointPhasesManagementIql(farmId, harvest, cultureId) {
        return new Promise((resolve, reject) => {
            ManagementService
                .getCollectionPointPhasesManagementIql(farmId, harvest, cultureId)
                .then(
                    success => {
                        const management = success;
                        management.data = this.newData(management.data);

                        resolve(management);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getFarmManagementIql(farmId, harvest, cultureId = "", filters) {
        return new Promise((resolve, reject) => {
            ManagementService
                .getFarmManagementIql(farmId, harvest, cultureId, filters)
                .then((success) => {
                    const {data} = success;
                    const list = Object.assign({}, {...data});
                    resolve(list);
                }, (error) => {
                    reject(error);
                })
        });
    }

    static createCollectionPoint(payload) {
        return new Promise((resolve, reject) => {
            ManagementService
                .createCollectionPoint(payload)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    static updateCollectionPoint(payload = {}) {
        return new Promise((resolve, reject) => {
            ManagementService
                .updateCollectionPoint(payload)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    static deleteCollectionPoint(payload) {
        return new Promise((resolve, reject) => {
            ManagementService
                .deleteCollectionPoint(payload)
                .then((success) => {
                    resolve(success);
                }, (error) => {
                    reject(error);
                });
        });
    }

    

    static getPointFile(pointId){
        return new Promise((resolve,reject)=>{
            ManagementService.getPointFile(pointId)
                .then(
                    (response)=>{
                        const { data } = response
                        resolve(data)
                    }, 
                    (error)=>{
                        reject(error)
                    }
                );
        })
    }
}

export default Management;
