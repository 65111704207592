import http from "@/services/http.service";

const apiUrl = "/management-indexes";

class ManagementIndexesService {
  static all() {
    return http.get(`${apiUrl}`);
  }
}

export default ManagementIndexesService;
