import IndirectIndexesService from './indirect-indexes.service';

class IndirectIndex {
    constructor(payload = {}) {
        Object.assign(this, {
            ...IndirectIndex.build(payload),
        });
    }

    static build(
        {
            difference = "",
            result = "",
            average = "",
        }
    ) {
        return {
            difference,
            result,
            average,
        };
    }

    static newData(payload) {
        return new IndirectIndex(payload);
    }

    static get(payload) {
        return new Promise((resolve, reject) => {
            IndirectIndexesService
                .get(payload)
                .then(
                    success => {
                        const indirectIndex = this.newData(success.data.data);
                        resolve(indirectIndex);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static saveData(payload) {
        return new Promise((resolve, reject) => {
            IndirectIndexesService
                .saveData(payload)
                .then(
                    () => {
                        resolve(true);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getData(id) {
        return new Promise((resolve, reject) => {
            IndirectIndexesService
                .getData(id)
                .then(
                    (res) => {
                        resolve(res.data.data.slice(-3));
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static average(payload) {
        return new Promise((resolve, reject) => {
            IndirectIndexesService
                .average(payload)
                .then(
                    success => {
                        const indirectIndex = this.newData(success.data.data);
                        resolve(indirectIndex);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }
}

export default IndirectIndex;
